import api from "./api";

// 멀티시그 지갑 생성
export const createMultisigWallet = async (ownerAddresses: string[]) => {
  try {
    // 참여자 수에 따라 threshold 설정

    const threshold = `0x${Math.ceil(ownerAddresses.length).toString(16)}`;

    // 멀티시그 지갑 생성 API 호출
    const response = await api.post("/multisig/wallet", {
      ownerAddresses,
      threshold: threshold,
      gasLimit: "0x284b744",
      gasPrice: "0x0",
    });

    return response.data;
  } catch (error) {
    console.error("멀티시그 지갑 생성 중 오류 발생:", error);
    throw error;
  }
};

// 멀티시그 지갑 오너 조회
export const getMultisigWalletOwners = async (multisigAddress: string) => {
  try {
    const response = await api.get(`/multisig/wallet/${multisigAddress}`);
    return response.data;
  } catch (error) {
    console.error("멀티시그 지갑 오너 조회 중 오류 발생:", error);
    throw error;
  }
};

// 서명 요청 목록 조회
export const getMultisigConfirmations = async (multisigAddress: string) => {
  try {
    const response = await api.get(
      `/multisig/wallet/${multisigAddress}/confirm`
    );
    return response.data;
  } catch (error) {
    console.error("서명 요청 목록 조회 중 오류 발생:", error);
    throw error;
  }
};

// 특정 오너의 멀티시그 지갑 조회
export const getMultisigWalletsByOwner = async (ownerAddress: string) => {
  try {
    const response = await api.get(`/multisig/wallet/owner/${ownerAddress}`);
    return response.data;
  } catch (error) {
    console.error("오너의 멀티시그 지갑 조회 중 오류 발생:", error);
    throw error;
  }
};
