export const badgeConfig = {
  size: 1000,
  textArea: {
    top: 660,
    left: 170,
    right: 170,
    bottom: 250,
  },
  minFontSize: 20,
  maxFontSize: 75, // Maximum font size to start with
  fontFamily: "Consolas, Courier New, monospace",
  shadowColor: "rgba(0, 0, 0, 0.7)", // Shadow color
  shadowBlur: 8, // Shadow blur radius
  shadowOffsetX: 4, // Shadow horizontal offset
  shadowOffsetY: 4, // Shadow vertical offset
};

// 유틸리티 함수: badge 이미지를 생성하는 함수
export const generateBadgeImage = (
  canvas: HTMLCanvasElement,
  name: string,
  role: "Influencer" | "Designer" | "Artist" | "Producer"
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      reject("Canvas not supported");
      return;
    }

    const baseBadgeImage = new Image();
    baseBadgeImage.src = `/assets/nft_${role}_base.png`;
    baseBadgeImage.onload = () => {
      // 배지 이미지 그리기
      ctx.clearRect(0, 0, canvas.width, canvas.height); // 이전 내용 지우기
      ctx.drawImage(baseBadgeImage, 0, 0, badgeConfig.size, badgeConfig.size);

      // 텍스트 배치 영역 계산
      const textAreaWidth =
        badgeConfig.size -
        badgeConfig.textArea.left -
        badgeConfig.textArea.right;
      const textAreaHeight =
        badgeConfig.size -
        badgeConfig.textArea.top -
        badgeConfig.textArea.bottom;

      // 초기 폰트 설정
      let fontSize = badgeConfig.maxFontSize;
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = `bold ${fontSize}px "Courier New", "Lucida Console", monospace`;

      // 텍스트 크기 조정
      while (
        ctx.measureText(name).width > textAreaWidth ||
        fontSize > textAreaHeight
      ) {
        fontSize--;
        if (fontSize < badgeConfig.minFontSize) {
          fontSize = badgeConfig.minFontSize;
          break;
        }
        ctx.font = `bold ${fontSize}px "Courier New", "Lucida Console", monospace`;
      }

      // 텍스트에 그림자 설정
      ctx.shadowColor = badgeConfig.shadowColor;
      ctx.shadowBlur = badgeConfig.shadowBlur;
      ctx.shadowOffsetX = badgeConfig.shadowOffsetX;
      ctx.shadowOffsetY = badgeConfig.shadowOffsetY;

      // 텍스트 그리기
      const textX = badgeConfig.size / 2;
      const textY = badgeConfig.textArea.top + textAreaHeight / 2;
      ctx.fillText(name, textX, textY);

      // 캔버스를 Data URL로 변환
      const badgeDataUrl = canvas.toDataURL("image/png");
      resolve(badgeDataUrl);
    };

    baseBadgeImage.onerror = (err) => {
      reject("Failed to load base image");
    };
  });
};
