import React from "react";
import { ModalProps } from "../interfaces";

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* 모달 */}
      <div className="relative w-[326px] h-[226px] bg-white shadow-lg flex-col justify-center items-center z-50">
        <div className="self-stretch h-[154px] px-4 py-8 flex-col justify-center items-center flex">
          <div className="self-stretch h-[90px] flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch text-center text-[#212121] text-xl font-semibold font-['Pretendard'] leading-relaxed">
              {title}
            </div>
            <div className="self-stretch text-center text-[#212121] text-base font-normal font-['Pretendard'] leading-relaxed">
              {description}
            </div>
          </div>
        </div>
        <div className="self-stretch px-4 py-3 bg-white justify-center items-center gap-3 inline-flex">
          {onClose && (
            <div
              className="w-[142px] h-12 p-3 bg-white border border-[#e5e5e6] justify-center items-center gap-2.5 flex cursor-pointer"
              onClick={onClose}
            >
              <div className="text-center text-black text-xs font-normal font-['Pretendard']">
                취소
              </div>
            </div>
          )}
          <div
            className={`${
              onClose ? "w-[142px]" : "w-[284px]"
            } h-12 p-3 bg-[#212121] justify-center items-center gap-1 flex cursor-pointer`}
            onClick={onConfirm}
          >
            <div className="text-center text-white text-xs font-normal font-['Pretendard']">
              확인
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
