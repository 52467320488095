import api from "./api";

// NFT 발행 요청 등록
export const confirmMintRequest = async (
  contractAddress: string,
  multisigAddress: string,
  toAddress: string,
  tokenUri: string,
  nftAddresses: string[]
) => {
  try {
    const response = await api.post("/collaboration/mint/confirm", {
      contractAddress,
      multisigAddress,
      toAddress,
      tokenUri,
      nftAddresses,
    });
    return response.data;
  } catch (error) {
    console.error("NFT 발행 요청 등록 중 오류 발생:", error);
    throw error;
  }
};

// 서명 진행
export const signMintRequest = async (confirmId: string) => {
  try {
    const response = await api.post("/collaboration/mint/sign", {
      confirmId,
    });
    return response.data;
  } catch (error) {
    console.error("서명 진행 중 오류 발생:", error);
    throw error;
  }
};

// 발행 승인
export const approveMintRequest = async (confirmId: string) => {
  try {
    const response = await api.post("/collaboration/mint/approve", {
      confirmId,
    });
    return response.data;
  } catch (error) {
    console.error("NFT 발행 승인 중 오류 발생:", error);
    throw error;
  }
};

// 발행 트랜잭션 조회
export const getMintTransaction = async (confirmId: string) => {
  try {
    const response = await api.get("/collaboration/mint/transaction", {
      params: { confirmId },
    });
    return response.data;
  } catch (error) {
    console.error("발행 트랜잭션 조회 중 오류 발생:", error);
    throw error;
  }
};
