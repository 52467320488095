import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserByEmail, updateUser } from "../../api/db/userApi"; // 닉네임을 가져오기 위한 API와 업데이트 API 불러오기
import Modal from "../../components/Modal";
import PageHeader from "../../components/PageHeader";
import SubmitButton from "../../components/SubmitButton";

const NicknamePage: React.FC = () => {
  const [nickname, setNickname] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchNickname = async () => {
    try {
      const email = localStorage.getItem("email");
      if (email) {
        const user = await fetchUserByEmail(email);
        setNickname(user.nickname || "");
      }
    } catch (error) {
      console.error("닉네임을 가져오는 중 오류 발생:", error);
    }
  };

  useEffect(() => {
    fetchNickname();
  }, []);

  useEffect(() => {
    if (nickname.trim() !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [nickname]);

  const handleSubmit = async () => {
    try {
      const email = localStorage.getItem("email");
      if (email) {
        await updateUser(email, {
          nickname: nickname,
        });
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("닉네임 업데이트 중 오류 발생:", error);
    }
  };

  // 모달 확인 버튼 클릭 시 처리
  const handleModalConfirm = () => {
    setIsModalOpen(false);
    navigate("/mypage");
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <div className="sticky top-0 z-10 bg-white ">
          <PageHeader title="닉네임 설정" />
        </div>

        <div className="flex-1 px-[29px] py-8 overflow-y-auto no-scrollbar">
          <div>
            <h1 className="text-[#212121] text-xl font-semibold">
              안녕하세요!
            </h1>
            <div className="mt-4 text-[#212121] text-sm font-semibold">
              닉네임을 설정해 주세요. 설정된 닉네임은 언제든지 변경할 수
              있습니다.
            </div>
            <div className="mt-6 w-[332px] h-[0px] border border-[#eaeaea]"></div>
          </div>

          <div>
            <p className="mt-[41px] mb-[10px] text-black text-base font-bold">
              닉네임을 입력해주세요.
            </p>
            {/* 닉네임을 입력하는 일반 input 필드 */}
            <input
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              placeholder="닉네임 입력"
              className="w-[332px] h-[52px] p-3.5 border border-black justify-center items-center gap-2.5 inline-flex"
            />
          </div>
        </div>

        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text="닉네임 제출"
            isDisabled={isDisabled}
            onClick={handleSubmit}
          />
          <div className="h-6 flex-col justify-start items-center flex"></div>
        </div>
      </div>

      {/* 모달 */}
      <Modal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        title="닉네임 저장 완료"
        description="닉네임이 성공적으로 저장되었습니다."
      />
    </div>
  );
};

export default NicknamePage;
