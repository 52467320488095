import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CollaborationManage from "./pages/collaboration/CollaborationManage";
import JobRegister from "./pages/job/JobRegister";
import JobRegisterComplete from "./pages/job/JobRegisterComplete";
import MyProfile from "./pages/mypage/MyProfile";
import NicknamePage from "./pages/mypage/NicknamePage";
import CollaborationTeamPage from "./pages/project/CollaborationTeamPage";
import NFTIssuanceComplete from "./pages/project/NFTIssuanceComplete";
import NFTIssuancePage from "./pages/project/NFTIssuancePage";
import ProjectAgreementPage from "./pages/project/ProjectAgreementPage";
import ProjectDetailsPage from "./pages/project/ProjectDetailsPage";
import ProjectIntroductionPage from "./pages/project/ProjectIntroductionPage";
import ProjectParticipationPage from "./pages/project/ProjectParticipationPage";
import LoginPage from "./pages/user/LoginPage";
import RegisterPage from "./pages/user/RegisterPage";
import "./styles/scrollbar.css";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* User */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        {/* 마이페이지 */}
        <Route path="/mypage" element={<MyProfile />} />
        <Route path="/nickname" element={<NicknamePage />} />
        <Route
          path="/collaboration/manage/:tab"
          element={<CollaborationManage />}
        />
        {/* 작업증명 관련 */}
        <Route path="/job/register" element={<JobRegister />} />
        <Route
          path="/job/register/complete"
          element={<JobRegisterComplete />}
        />
        {/* 프로젝트 생성 */}
        <Route
          path="/project/nft-issuance/complete/:projectId"
          element={<NFTIssuanceComplete />}
        />
        <Route
          path="/project/introduction"
          element={<ProjectIntroductionPage />}
        />
        <Route
          path="/project/team/:projectId"
          element={<CollaborationTeamPage />}
        />
        <Route
          path="/project/agreement/:projectId"
          element={<ProjectAgreementPage />}
        />
        <Route
          path="/project/nft-issuance/:projectId"
          element={<NFTIssuancePage />}
        />
        <Route
          path="/project/participation/:projectId"
          element={<ProjectParticipationPage />}
        />
        <Route
          path="/project/detail/:projectId"
          element={<ProjectDetailsPage />}
        />
      </Routes>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </Router>
  );
};

export default App;
