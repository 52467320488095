import api from "./api";

// 작업증명 컨트랙트 배포
export const deployProofOfWorkContract = async (
  signerAddress: string,
  name: string,
  symbol: string
) => {
  try {
    const response = await api.post("/jobsbt/deploy", {
      signerAddress,
      name,
      symbol,
    });
    return response.data;
  } catch (error) {
    console.error("작업증명 컨트랙트 배포 중 오류 발생:", error);
    throw error;
  }
};

// 작업증명 컨트랙트 NFT 발행 실행
export const mintProofOfWorkNFT = async (
  contractAddress: string,
  toAddress: string,
  tokenUri: string,
  gasLimit?: string,
  gasPrice?: string,
  wid?: string
) => {
  try {
    const response = await api.post("/jobsbt/mint", {
      contractAddress,
      toAddress,
      tokenUri,
      gasLimit,
      gasPrice,
      wid,
    });
    return response.data;
  } catch (error) {
    console.error("작업증명 컨트랙트 NFT 발행 중 오류 발생:", error);
    throw error;
  }
};

// 작업증명 컨트랙트 목록 조회
export const getProofOfWorkContracts = async (signerAddress: string) => {
  try {
    const response = await api.get("/jobsbt/contract", {
      params: { signerAddress },
    });
    return response.data;
  } catch (error) {
    console.error("작업증명 컨트랙트 조회 중 오류 발생:", error);
    throw error;
  }
};
