import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProjects } from "../../api/db/projectApi";
import { fetchUserByEmail } from "../../api/db/userApi";
import CollaborationCard from "../../components/CollaborationCard";
import PageHeader from "../../components/PageHeader";

const CollaborationManage: React.FC = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email") || "";
  const { tab } = useParams<{ tab: string }>();
  const [nickname, setNickname] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [profileImageUrl, setProfileImageUrl] = useState<string>("");
  const [projects, setProjects] = useState<any[]>([]);
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string | undefined>(tab);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSortedByLatest, setIsSortedByLatest] = useState<boolean>(false);

  // 사용자 정보와 프로젝트 정보 불러오기
  const fetchUserInfo = async () => {
    if (!email) {
      console.error("이메일이 존재하지 않습니다.");
      setIsLoading(false);
      return; // 이메일이 없으면 함수 종료
    }

    try {
      const user = await fetchUserByEmail(email);
      if (!user) {
        console.error("사용자가 존재하지 않습니다.");
        setIsLoading(false);
        return; // 사용자 정보가 없으면 종료
      }

      setNickname(user.nickname || "");
      setUserRole(user.role || "");
      setUserId(user.id || "");
      setProfileImageUrl(user.profileImageUrl);

      // 모든 프로젝트 정보 한 번만 가져오기
      const fetchedProjects = await fetchProjects();
      setAllProjects(fetchedProjects); // 모든 프로젝트 저장
      handleTabProjects(fetchedProjects, user.role);
      setIsLoading(false);
    } catch (error) {
      console.error("정보를 불러오는 중 오류 발생:", error);
      setIsLoading(false);
    }
  };

  // 탭에 따른 프로젝트 필터링 처리 함수
  const handleTabProjects = (allProjects: any[], userRole: string) => {
    if (tab === "availableProjects") {
      const availableProjects = allProjects.filter(
        (project: any) =>
          project.requiredRoles.includes(userRole) &&
          project.projectStatus === "모집중"
      );
      setProjects(availableProjects);
      setActiveTab("availableProjects");
    } else {
      const myProjects = allProjects.filter((project: any) => {
        const creatorEmail = project.creator?.email; // null 체크
        return (
          creatorEmail === email ||
          (project.participants &&
            project.participants.some(
              (p: any) => p.user && p.user.id === userId // null 체크
            )) ||
          (project.applications &&
            project.applications.some(
              (app: any) => app.user && app.user.id === userId // null 체크
            ))
        );
      });
      setProjects(myProjects);
      setActiveTab("myProjects");
    }
  };

  useEffect(() => {
    fetchUserInfo(); // 처음에만 데이터를 불러옴
  }, [email]); // 이메일이 변경될 때마다 실행

  useEffect(() => {
    // 탭 변경 시 이미 불러온 프로젝트에서 필터링
    if (allProjects.length > 0) {
      handleTabProjects(allProjects, userRole);
    }
  }, [allProjects, tab, userRole]);

  // 날짜를 기준으로 정렬하는 함수
  const sortByDate = (projects: any[]) => {
    return [...projects].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  };

  // 참여 상태 계산 함수
  const getParticipantStatus = (project: any) => {
    const isParticipant = project.participants?.some(
      (p: any) => p.user && p.user.id === userId // null 체크
    );
    const isApplicant = project.applications?.some(
      (app: any) => app.user && app.user.id === userId // null 체크
    );

    if (isApplicant && !isParticipant) {
      switch (project.projectStatus) {
        case "모집중":
          return "신청";
        case "협의중":
        case "진행중":
          return "미선정";
        case "취소":
          return "취소";
        default:
          return project.projectStatus;
      }
    }

    if (isParticipant) {
      switch (project.projectStatus) {
        case "협의중":
        case "진행중":
        case "취소":
        case "완료":
          return project.projectStatus;
        default:
          return project.projectStatus;
      }
    }

    return project.projectStatus;
  };

  // 프로젝트 클릭 시 참여 페이지로 이동하는 함수
  const handleProjectClick = (project: any) => {
    const participantStatus = getParticipantStatus(project);

    if (participantStatus === "미선정" || project.projectStatus === "취소") {
      return;
    }

    if (project.creator && project.creator.email === email) {
      if (project.projectStatus === "모집중") {
        navigate(`/project/team/${project.id}`);
      } else if (project.projectStatus === "협의중") {
        if (isContributionSet(project)) {
          navigate(`/project/nft-issuance/${project.id}`);
        } else {
          navigate(`/project/agreement/${project.id}`);
        }
      } else if (project.projectStatus === "진행중") {
        navigate(`/project/detail/${project.id}`);
      } else {
        navigate(`/project/team/${project.id}`);
      }
    } else if (project.projectStatus === "모집중") {
      navigate(`/project/participation/${project.id}`);
    } else if (project.projectStatus === "협의중") {
      if (isContributionSet(project)) {
        navigate(`/project/nft-issuance/${project.id}`);
      } else {
        navigate(`/project/agreement/${project.id}`);
      }
    } else if (project.projectStatus === "진행중") {
      navigate(`/project/detail/${project.id}`);
    } else {
      navigate(`/project/participation/${project.id}`);
    }
  };

  // 기여도가 설정되었는지 확인하는 함수
  const isContributionSet = (project: any) => {
    return project.participants?.some(
      (participant: any) =>
        participant.user &&
        participant.user.id === userId &&
        participant.contribution > 0
    );
  };

  // 탭 변경 시 필터링된 프로젝트 설정
  const handleTabChange = (tab: "myProjects" | "availableProjects") => {
    if (activeTab === tab) {
      return;
    }

    setActiveTab(tab);

    if (tab === "myProjects") {
      const myProjects = allProjects.filter(
        (project: any) =>
          project.creator?.id === userId || // null 체크
          project.participants?.some(
            (participant: any) =>
              participant.user && participant.user.id === userId // null 체크
          )
      );
      setProjects(myProjects);
      navigate("/collaboration/manage/myProjects");
    } else {
      const availableProjects = allProjects.filter((project: any) =>
        project.requiredRoles.includes(userRole)
      );
      setProjects(availableProjects);
      navigate("/collaboration/manage/availableProjects");
    }
  };

  // 최신순 정렬 토글 버튼
  const toggleSortByLatest = () => {
    setProjects((prevProjects) =>
      isSortedByLatest ? prevProjects : sortByDate(prevProjects)
    );
    setIsSortedByLatest(!isSortedByLatest);
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 min-h-screen">
      <div className="w-full max-w-[390px] bg-white relative flex flex-col min-h-screen">
        {/* 헤더 */}
        <div className="sticky top-0 z-10 bg-white">
          <PageHeader
            title="협업"
            rightIcon={
              <div className="flex items-center gap-2.5">
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5559 2L14.2225 3.66667H9.7781L11.4448 2H12.5559ZM11.4451 4.22217L11.445 4.22222H12.5559H14.7781L16.4448 5.88889L18.667 8.11111V10.3333L18.6667 10.333V15.8887L20.3333 17.5553V19.222H17.001L17.0007 19.2223H11.4451H7.00065L7.00033 19.222V19.2222H3.66699V17.5556L5.33366 15.8889H5.33398V10.3333V8.11106V8.111L7.00065 6.44434V6.44439L7.55615 5.88889H7.55588L9.22255 4.22222H9.22282L9.22287 4.22217H11.4451ZM17 17.5557V15.8887V8.66634L14.2225 5.88889H9.77838L7.00065 8.66661V17.5557H13.1118H17ZM9.22255 20.3333L10.8892 22H13.1114L14.7781 20.3333H9.22255Z"
                      fill="#221815"
                    />
                  </svg>
                  <div className="w-[7px] h-[7px] bg-[#ff3c12] rounded-full border border-white absolute top-0 right-0" />
                </div>
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 0.5H5L3.5 2V5.5H1.5H0V7V18L1.5 19.5H14.5L16 18V7V5.5H14.5H12.5V2L11 0.5ZM11 7V9H12.5V7H14.5V18H1.5V7H3.5V9H5V7H11ZM11 5.5V2H5V5.5H11Z"
                      fill="#241E1B"
                    />
                  </svg>

                  <div className="w-2.5 h-2.5 bg-[#ff3c12] rounded-full absolute top-0 right-0">
                    <div className="text-center text-white text-[6px] leading-[7px]">
                      4
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>

        {/* 사용자 정보 및 새 프로젝트 버튼 */}
        <div className="flex flex-col items-center text-center h-56 bg-[#f7f7f7]">
          <img
            className="mt-6 mb-4 w-[86px] h-[86px]"
            src={profileImageUrl}
            alt="badge"
          />
          <div className="text-[#838383] text-xs font-semibold font-['Pretendard']">
            {userRole}
          </div>
          <div className="text-[#212121] text-xl font-semibold font-['Pretendard']">
            {nickname}님
          </div>
          <div
            className="mt-[10px] h-[29px] px-3 py-2 bg-white rounded-[28px] border border-[#d7d7d7] justify-center items-center gap-2.5 inline-flex cursor-pointer"
            onClick={() => navigate("/project/introduction")}
          >
            <div className="text-[#4400ff] text-[11px] font-extrabold font-['Pretendard']">
              새 프로젝트 +
            </div>
          </div>
        </div>

        {/* 프로젝트 목록 */}
        <div className="flex-1 py-4 overflow-y-auto no-scrollbar">
          <div className="w-full px-4 flex justify-between items-center h-10 mb-4">
            <div className="text-[#212121] text-base font-semibold font-['Pretendard']">
              프로젝트
            </div>
            <div
              className="flex items-center gap-1 bg-white py-2.5 px-3 rounded-[50px] cursor-pointer"
              onClick={toggleSortByLatest}
            >
              <div className="text-[#212121] text-xs font-normal font-['Pretendard']">
                {isSortedByLatest ? "오래된순" : "최신순"}
              </div>
              <div className="w-5 h-5 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8333 6.66675L14.375 6.66675L10.0004 11.0416L10.0004 12.4999L15.8333 6.66675ZM9.99963 11.0416L9.99963 12.4999L4.16678 6.66675L5.62499 6.66675L9.99963 11.0416Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* 탭 */}
          <div className="w-full px-4 border-b border-[#dddddd] flex justify-start items-center gap-4 mt-4">
            <button
              className={`py-1 text-sm font-semibold font-['Pretendard'] ${
                activeTab === "availableProjects"
                  ? "border-b-2 border-black text-[#212121]"
                  : "text-[#adadad]"
              }`}
              onClick={() => handleTabChange("availableProjects")}
            >
              참여 가능한 프로젝트
            </button>
            <button
              className={`py-1 text-sm font-semibold font-['Pretendard'] ${
                activeTab === "myProjects"
                  ? "border-b-2 border-black text-[#212121]"
                  : "text-[#adadad]"
              }`}
              onClick={() => handleTabChange("myProjects")}
            >
              나의 프로젝트
            </button>
          </div>

          <div className="px-4">
            {/* 프로젝트 목록 */}
            {isLoading ? (
              <div className="text-center text-[#7e7d7d] text-sm font-medium font-['Pretendard']">
                로딩 중...
              </div>
            ) : projects.length > 0 ? (
              projects.map((project) => (
                <CollaborationCard
                  key={project.id}
                  status={
                    activeTab === "myProjects"
                      ? getParticipantStatus(project)
                      : project.projectStatus
                  }
                  title={project.name}
                  onClick={() => handleProjectClick(project)}
                  isDisabled={
                    getParticipantStatus(project) === "미선정" ||
                    project.projectStatus === "취소"
                  }
                />
              ))
            ) : (
              <div className="w-full mt-10 text-center text-[#7e7d7d] text-sm font-medium font-['Pretendard']">
                진행 중인 프로젝트가 없어요.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationManage;
