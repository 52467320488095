import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deployProofOfWorkContract,
  mintProofOfWorkNFT,
} from "../../api/cw/jobSbtApi";
import { fetchUserByEmail, updateUser } from "../../api/db/userApi";
import LinkInput from "../../components/LinkInput";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import RoleSelection from "../../components/RoleSelection";
import SubmitButton from "../../components/SubmitButton";
import { generateBadgeImage } from "../../utils/badgeGenerator";
import { uploadFile, uploadJsonFile } from "../../utils/uploadFile";

const JobRegister: React.FC = () => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const email = localStorage.getItem("email");
  const [id, setId] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [role, setRole] = useState<string[]>([]);
  const [activityProofLink, setActivityProofLink] = useState<string>("");
  const [isLinkValid, setIsLinkValid] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [badgeDataUrl, setBadgeDataUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (role.length > 0 && activityProofLink.trim() !== "" && isLinkValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [role, activityProofLink, isLinkValid]);

  const fetchNickname = async () => {
    if (email) {
      const user = await fetchUserByEmail(email);
      setId(user.id || "");
      setNickname(user.nickname || "");
      setWalletAddress(user.walletAddress || "");
    }
  };

  useEffect(() => {
    fetchNickname();
  }, []);

  const generateUniqueContractName = () => {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    return `ProofOfWork_${email}_${timestamp}`;
  };

  useEffect(() => {
    if (canvasRef.current && nickname && role.length > 0) {
      const canvas = canvasRef.current;
      generateBadgeImage(
        canvas,
        nickname,
        role[0] as "Influencer" | "Designer" | "Artist" | "Producer"
      )
        .then((dataUrl) => {
          setBadgeDataUrl(dataUrl);
        })
        .catch((error) => {
          console.error("Badge generation failed:", error);
        });
    }
  }, [nickname, role]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (email && id && badgeDataUrl) {
      try {
        const badgeBlob = await fetch(badgeDataUrl).then((res) => res.blob());
        const badgeImageFile = new File([badgeBlob], `${id}_badge.png`, {
          type: "image/png",
        });

        const uploadedImagePath = await uploadFile(
          badgeImageFile,
          `dodress/badge/`
        );

        if (!uploadedImagePath) {
          console.error("파일 업로드에 실패했습니다.");
          setIsLoading(false);
          return;
        }

        const userMetadata = {
          name: email,
          description: `Proof of work NFT for ${email}`,
          attributes: [
            { trait_type: "Nickname", value: nickname },
            { trait_type: "Role", value: role[0] },
            { trait_type: "Activity Proof Link", value: activityProofLink },
          ],
          image: uploadedImagePath,
        };

        const uploadedJsonPath = await uploadJsonFile(
          userMetadata,
          `dodress/json/`
        );
        if (!uploadedJsonPath) {
          console.error("JSON 파일 업로드 실패");
          setIsLoading(false);
          return;
        }

        const signerAddress = walletAddress;
        const contractName = generateUniqueContractName();
        const contractSymbol = "ETH";

        const deployedContract = await deployProofOfWorkContract(
          signerAddress,
          contractName,
          contractSymbol
        );

        const contractAddress = deployedContract.message;

        const nftMintResponse = await mintProofOfWorkNFT(
          contractAddress,
          walletAddress,
          uploadedJsonPath
        );

        const confirmId = nftMintResponse.item;

        await updateUser(email, {
          proofOfWorkNftContractAddress: contractAddress,
          proofOfWorkNftMintConfirmationId: confirmId,
          role: role[0],
          activityProofLink,
          profileImageUrl: uploadedImagePath,
        });

        navigate("/job/register/complete");
      } catch (error) {
        console.error("작업 처리 중 오류 발생:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen relative">
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <LoadingSpinner />
        </div>
      )}

      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <div className="sticky top-0 z-10 bg-white">
          <PageHeader title="협업 등록" />
        </div>

        <div className="flex-1 px-[29px] py-8 overflow-y-auto no-scrollbar">
          <div>
            <h1 className="text-[#212121] text-xl font-semibold">
              {nickname}님
            </h1>
            <div className="mt-4 text-[#212121] text-sm font-semibold">
              아래 내용을 제출하시면 확인 절차를 거쳐 가입하신 이메일로 인증
              번호를 발급해드립니다.
            </div>
            <div className="mt-6 w-[332px] h-[0px] border border-[#eaeaea]"></div>
          </div>

          <div>
            <p className="mt-[41px] mb-[10px] text-black text-base font-bold">
              참여하고자 하는 역할을 선택해주세요.
            </p>
            <RoleSelection
              onSelectRole={(selectedRoles: string[]) => setRole(selectedRoles)}
              allowMultipleSelection={false}
            />
            <div className="w-[332px] h-[0px] border border-[#eaeaea]"></div>
          </div>
          <p className="mt-8 mb-[10px] text-black text-base font-bold">
            활동을 증명할 수 있는 사이트 혹은 SNS 링크를 공유해주세요.
          </p>
          <LinkInput
            value={activityProofLink}
            onChange={setActivityProofLink}
            placeholder={"링크 입력"}
            className={
              "w-[332px] h-[52px] p-3.5 border border-black justify-center items-center gap-2.5 inline-flex"
            }
            onValidChange={setIsLinkValid}
          />
        </div>
        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text="협업자 인증 정보 제출"
            isDisabled={isDisabled}
            onClick={handleSubmit}
          />
        </div>
        <canvas
          ref={canvasRef}
          style={{ display: "none" }}
          width={1000}
          height={1000}
        />
      </div>
    </div>
  );
};

export default JobRegister;
