import React, { useEffect } from "react";

const LoadingSpinner: React.FC = () => {
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `@keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }`;

    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  return (
    <div style={spinnerContainerStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
};

const spinnerContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const spinnerStyle: React.CSSProperties = {
  border: "4px solid rgba(0, 0, 0, 0.1)",
  borderTop: "4px solid #3498db",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: "spin 1s linear infinite",
};

export default LoadingSpinner;
