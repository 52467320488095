import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/cw/authApi";
import Modal from "../../components/Modal";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // 일반 로그인 처리
  const handleLogin = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      await login(email, password);
      navigate("/mypage");
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage(
        "로그인에 실패했습니다. 이메일과 비밀번호를 확인해주세요."
      ); // 에러 메시지 설정
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = () => {
    navigate("/register");
  };

  // SNS 로그인 처리
  const handleSnsLogin = async (type: string) => {
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
        <div className="flex items-center justify-center mb-8">
          <img
            src="../../assets/images/logo.svg"
            alt="ABC Wallet"
            className="w-[120px]"
          />
        </div>
        {/* 이메일과 비밀번호 입력 */}
        <div className="mb-6">
          <input
            type="email"
            placeholder="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
          <input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
          {errorMessage && (
            <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
          )}
          <button
            onClick={handleLogin}
            className={`w-full py-3 rounded-lg font-semibold transition duration-300 ${
              isLoading
                ? "bg-gray-400 text-white cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700 text-white"
            }`}
            disabled={isLoading}
          >
            {isLoading ? "로그인 중..." : "로그인"}
          </button>
        </div>

        {/* 회원가입 버튼 */}
        <div className="mb-6">
          <button
            onClick={handleSignup}
            className="w-full bg-gray-200 hover:bg-gray-300 text-black py-3 rounded-lg font-semibold transition duration-300"
          >
            회원가입
          </button>
        </div>

        {/* 소셜 로그인 섹션 */}
        <div className="text-center text-[#8f93a2] text-[15px] font-medium mb-4">
          소셜로그인으로 편리하게 시작하세요
        </div>
        <div className="flex justify-around mb-6">
          <img
            src="../../assets/images/logo/google.svg"
            alt="Google Logo"
            className={`w-12 h-12 transition transform hover:scale-110`}
            onClick={() => handleSnsLogin("google")}
          />
          <img
            src="../../assets/images/logo/apple.svg"
            alt="Apple Logo"
            className={`w-12 h-12 transition transform hover:scale-110`}
            onClick={() => handleSnsLogin("apple")}
          />
          <img
            src="../../assets/images/logo/naver.svg"
            alt="Naver Logo"
            className={`w-12 h-12 transition transform hover:scale-110`}
            onClick={() => handleSnsLogin("naver")}
          />
          <img
            src="../../assets/images/logo/kakao.svg"
            alt="Kakao Logo"
            className={`w-12 h-12 transition transform hover:scale-110`}
            onClick={() => handleSnsLogin("kakao")}
          />
        </div>
      </div>

      {/* SNS 로그인 준비 중 모달 */}
      <Modal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        title="SNS 로그인 준비 중"
        description="SNS 로그인 기능은 준비 중입니다."
      />
    </div>
  );
}

export default LoginPage;
