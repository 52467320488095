import React, { useEffect } from "react";
import { Step3Props } from "../../interfaces";
import StepIndicator from "../StepIndicator";

const Step3: React.FC<Step3Props> = ({
  setIsFormValid,
  participants,
  setParticipants,
  projectInfo,
}) => {
  useEffect(() => {
    const totalContribution = participants.reduce(
      (total, participant) => total + participant.contribution,
      0
    );
    setIsFormValid(totalContribution === 100);
  }, [participants, setIsFormValid]);

  const handleContributionChange = (index: number, value: string) => {
    const updatedParticipants = [...participants];
    const newContribution = parseInt(value) || 0;

    // 기여도 업데이트 (로컬 상태만 업데이트)
    updatedParticipants[index].contribution = newContribution;
    setParticipants(updatedParticipants);
  };

  const shortenWalletAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div className="relative bg-white">
      <StepIndicator stepInfo={"step3"} description="프로젝트 협의" />

      {/* 프로젝트명 및 지갑주소 */}
      <div className="flex flex-col gap-4">
        <div className="flex items-center mt-[19px]">
          <div className="text-[#858585] text-xs font-semibold leading-7 w-[90px]">
            프로젝트명
          </div>
          <div className="text-[#999999] text-sm font-normal p-1 rounded ml-[15px]">
            {projectInfo.name}
          </div>
        </div>

        <div className="flex flex-start">
          <div className="text-[#858585] text-xs font-semibold leading-7 w-[90px]">
            프로젝트 지갑주소
          </div>
          <div className="text-[#999999] text-sm font-normal p-1 rounded w-[235px] break-words ml-[15px]">
            {projectInfo.multisigAddress}
          </div>
        </div>
      </div>

      {/* 참여자 섹션 */}
      <div className="mt-[50px] w-full flex-col justify-start items-start gap-2.5 inline-flex">
        <div className="text-black text-[15px] font-semibold">참여자</div>
        <div className="text-[#ff6422] text-[13px] font-normal">
          *기여도의 합은 100%가 되어야 합니다.
        </div>
        <div className="self-stretch px-2.5 py-1 rounded-[5px] border border-[#dddddd] flex-col justify-start items-start gap-2.5 flex">
          {participants.map((participant, index) => (
            <div
              key={index}
              className={`self-stretch px-3 py-2 justify-start items-center gap-1 inline-flex ${
                index !== participants.length - 1
                  ? "border-b border-[#dddddd]"
                  : ""
              }`}
            >
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="text-[#8d8d8d] text-xs font-medium">
                    {participant.role} |
                  </div>
                  <div className="text-[#212121] text-sm font-normal">
                    {participant.user.nickname}
                  </div>
                </div>
                <div className="text-[#898989] text-sm font-medium">
                  {shortenWalletAddress(participant.user.walletAddress || "")}
                </div>
              </div>

              <div className="py-[3px] rounded border border-black flex-col justify-center items-center gap-2.5 inline-flex">
                <input
                  value={participant.contribution}
                  onChange={(e) =>
                    handleContributionChange(index, e.target.value)
                  }
                  className="w-[30px] text-center"
                />
              </div>
              <div className="text-[#212121] text-sm">%</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step3;
