import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";

const getAccessToken = () => localStorage.getItem("access_token");
const getRefreshToken = () => localStorage.getItem("refresh_token");

const refreshAccessToken = async (): Promise<string | null> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw new Error("Refresh token is missing.");
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/auth/reissue`,
      {
        refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status === 201) {
      const { access_token, refresh_token } = response.data.item;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      return access_token;
    }
  } catch (error) {
    console.error("Failed to refresh access token:", error);
  }

  return null;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_SB_URL,
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();

        if (newAccessToken) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error("Failed to refresh access token:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    if (
      error.response &&
      error.response.status === 400 &&
      (error.response.data as any)?.code === "604"
    ) {
      console.error("Failed to renew token. Redirecting to login.");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default api;
