import React from "react";
import { CollaborationCardProps } from "../interfaces";

const getStatusStyle = (status: string) => {
  switch (status) {
    case "모집중":
      return "bg-[#4971fe]";
    case "신청":
      return "bg-[#27D97D]";
    case "미선정":
      return "bg-[#8E8E8E]";
    case "협의중":
      return "bg-[#BF6BFF]";
    case "완료":
      return "bg-black";
    case "진행중":
      return "bg-[#ff5e5e]";
    case "취소":
      return "bg-[#8d8d8d]";
    default:
      return "bg-[#8E8E8E]";
  }
};

const CollaborationCard: React.FC<CollaborationCardProps> = ({
  status,
  title,
  onClick,
  isDisabled = false,
}) => {
  return (
    <div
      className={`flex items-center justify-between h-16 border-b border-[#f1f1f3] p-2 ${
        isDisabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={!isDisabled ? onClick : undefined}
    >
      {/* 상태 표시 */}
      <div className="flex items-center">
        <div
          className={`px-2 py-1 ${getStatusStyle(
            status
          )} flex justify-center items-center`}
        >
          <span className="text-white text-[11px] font-semibold font-['Pretendard']">
            {status}
          </span>
        </div>
      </div>

      {/* 제목 */}
      <div className="flex-grow mx-4">
        <span className="text-[#212121] text-sm font-normal font-['Pretendard']">
          {title}
        </span>
      </div>

      {/* 아이콘 */}
      {!isDisabled && (
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 16.5L13 12L8.5 7.5L8.5 5L15.5 12L8.5 19L8.5 16.5Z"
              fill="#212121"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default CollaborationCard;
