import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProjectById } from "../../api/db/projectApi";
import SubmitButton from "../../components/SubmitButton";

const NFTIssuanceComplete: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // 프로젝트 정보 불러오기
  useEffect(() => {
    const getProject = async () => {
      try {
        if (projectId) {
          const projectData = await fetchProjectById(projectId);
          setProject(projectData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("프로젝트 정보를 가져오는 중 오류 발생:", error);
        setIsLoading(false);
      }
    };

    getProject();
  }, [projectId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>로딩 중...</div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>프로젝트 정보를 찾을 수 없습니다.</div>
      </div>
    );
  }

  return (
    <div
      className="flex justify-center items-center bg-gray-100"
      style={{ height: "100vh" }}
    >
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col ">
        <div className="w-[390px] h-10 pl-[25px] pr-[15px] py-[11px]"></div>

        <div className="flex-1 px-[24px] py-[38px] overflow-y-auto no-scrollbar">
          <div className="px-[7px] mb-6">
            <h1 className="text-[#212121] text-xl font-semibold">
              {project.name}
            </h1>
            <div className="mt-4 text-[#212121] text-sm font-semibold font-['Pretendard']">
              Collab-NFT가 발행되었습니다. 이제 제품을 출시하세요!
            </div>
          </div>
          <div className="w-[342px] px-[15px] py-7 bg-[#f6f6f6] flex flex-col justify-start items-center gap-6">
            {/* Badge Section */}
            <img
              className="w-[231.52px] h-[231.52px]"
              src={project.nftImageUrl || "/images/Badge_nft.svg"}
              alt="badge"
            />

            {/* User Info Section */}
            <div className="flex flex-col items-center gap-1">
              <div className="text-black text-xl font-bold font-['Pretendard']">
                {project.name}
              </div>
              <div className="text-[#999999] text-sm font-bold font-['Pretendard']">
                Collab-NFT
              </div>
            </div>

            {/* Badge Description */}
            <div className="w-[284px] text-center text-black text-base font-bold font-['Pretendard'] leading-normal">
              이 NFT는{" "}
              {project.participants
                .map(
                  (participant: {
                    user: { nickname: string; role: string };
                  }) => {
                    return `${participant.user.role} ${participant.user.nickname}`;
                  }
                )
                .filter((item: string | null) => item !== null)
                .join(", ")}{" "}
              의 컬래버레이션 창작물입니다.
            </div>

            {/* View Details Link */}
            <div className="text-[#454545] text-sm font-normal font-['Pretendard'] underline cursor-pointer">
              <a
                href={`${process.env.REACT_APP_EXPLORER_URL}/tx/${project.transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                등록 정보 보러가기
              </a>
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text="확인"
            isDisabled={false}
            link={`/project/detail/${project.id}`}
          />
          <div className="h-6 flex-col justify-start items-center flex"></div>
        </div>
      </div>
    </div>
  );
};

export default NFTIssuanceComplete;
