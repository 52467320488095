import axios from "axios";

// 모든 사용자 가져오기
export const fetchUsers = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/users`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

// 특정 사용자 가져오기
export const fetchUserByEmail = async (email: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/users/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

// 사용자 생성
export const createUser = async (email: string, password: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/users`,
      {
        email,
        password,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

// 사용자 수정
export const updateUser = async (
  email: string,
  user: {
    email?: string;
    password?: string;
    nickname?: string;
    walletAddress?: string;
    role?: string;
    activityProofLink?: string;
    profileImageUrl?: string;
    proofOfWorkNftContractAddress?: string;
    proofOfWorkNftMintConfirmationId?: string;
  }
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/users/${email}`,
      user
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// 사용자 삭제
export const deleteUser = async (email: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/users/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
