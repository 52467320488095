import React from "react";
import { useNavigate } from "react-router-dom";

const MenuItems: React.FC = () => {
  const navigate = useNavigate();

  const myItems = [
    { name: "회원 정보 수정", path: "/nickname" },
    { name: "주문/배송내역" },
    { name: "리뷰 관리" },
    { name: "쿠폰 등록" },
    { name: "포인트 내역" },
    { name: "좋아요" },
    { name: "팔로잉" },
    { name: "커스텀 저장내역" },
  ];

  const customerSupportItems = [
    { name: "상품 문의" },
    { name: "1:1 문의" },
    { name: "알림 설정" },
    { name: "공지사항" },
    { name: "FAQ" },
  ];

  const renderItems = (items: { name: string; path?: string }[]) =>
    items.map((item, index) => (
      <div
        key={index}
        className="self-stretch h-16 border-b border-[#f1f1f3] justify-between items-center inline-flex cursor-pointer"
        onClick={() => {
          if (item.path) {
            navigate(item.path);
          }
        }}
      >
        <div className="grow shrink basis-0 h-[18px] justify-start items-center gap-1.5 flex">
          <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
            <div className="self-stretch h-[18px] text-black text-base">
              {item.name}
            </div>
          </div>
        </div>
        <div className="w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 16.5L13 12L8.5 7.5L8.5 5L15.5 12L8.5 19L8.5 16.5Z"
              fill="#212121"
            />
          </svg>
        </div>
      </div>
    ));

  return (
    <div className="self-stretch flex-col justify-start items-start px-[14px] flex">
      {/* My Items Section */}
      <div className="self-stretch flex-col justify-start items-start flex">
        <div className="self-stretch h-[42px] justify-start items-center gap-2 inline-flex">
          <div className="text-[#858585] text-xs font-semibold">My</div>
        </div>
        <div className="self-stretch flex-col justify-start items-start flex">
          {renderItems(myItems)}
        </div>
      </div>

      {/* Customer Support Section */}
      <div className="mt-10 self-stretch flex-col justify-start items-start gap-2 flex">
        <div className="self-stretch h-[42px] justify-start items-center gap-2 inline-flex">
          <div className="text-[#858585] text-xs font-semibold">고객지원</div>
        </div>
        <div className="self-stretch flex-col justify-start items-start flex">
          {renderItems(customerSupportItems)}
        </div>
      </div>
    </div>
  );
};

export default MenuItems;
