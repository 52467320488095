import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createUser, fetchUserByEmail, updateUser } from "../api/db/userApi";
import { fetchUserInfo } from "../api/cw/authApi";

const MyInfo: React.FC = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const password = sessionStorage.getItem("password");
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [activityProofLink, setActivityProofLink] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const loadUserInfo = async () => {
    if (email) {
      try {
        let user = await fetchUserByEmail(email);

        // 사용자가 있으면 바로 처리
        setNickname(user.nickname || "");
        setActivityProofLink(user.activityProofLink || "");
        setRole(user.role || "");

        // 닉네임이 없는 경우 닉네임 설정 페이지로 이동
        if (!user.nickname) {
          navigate("/nickname");
        }

        // 지갑 주소가 없는 경우 처리
        if (!user.walletAddress) {
          const userInfo = await fetchUserInfo();
          console.log("userInfo", userInfo.walletAddress);
          setWalletAddress(userInfo.walletAddress);

          // 서버에 지갑 주소 업데이트
          await updateUser(email, { walletAddress: userInfo.walletAddress });
        } else {
          setWalletAddress(user.walletAddress);
        }
      } catch (error: any) {
        // 404 에러이면서 "User not found" 메시지가 있는 경우
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "User not found"
        ) {
          try {
            // 사용자가 없으면 새 사용자 생성
            console.log("사용자가 존재하지 않음, 새로 생성 중...");
            await createUser(email, password!);

            // 생성된 사용자 다시 조회
            const user = await fetchUserByEmail(email);

            // 새로 생성된 사용자 정보 설정
            setNickname(user.nickname || "");
            setActivityProofLink(user.activityProofLink || "");
            setRole(user.role || "");

            // 닉네임이 없는 경우 닉네임 설정 페이지로 이동
            if (!user.nickname) {
              navigate("/nickname");
            }

            // 지갑 주소가 없는 경우 처리
            if (!user.walletAddress) {
              const userInfo = await fetchUserInfo();
              console.log("userInfo", userInfo.walletAddress);
              setWalletAddress(userInfo.walletAddress);

              // 서버에 지갑 주소 업데이트
              await updateUser(email, {
                walletAddress: userInfo.walletAddress,
              });
            } else {
              setWalletAddress(user.walletAddress);
            }
          } catch (createError) {
            console.error("사용자 생성 중 오류 발생:", createError);
            toast.error("사용자를 생성하는 중 오류가 발생했습니다.");
          }
        } else {
          console.error("사용자 정보를 불러오는 중 오류 발생:", error);
          toast.error("사용자 정보를 불러오는 중 오류가 발생했습니다.");
        }
      } finally {
        sessionStorage.removeItem("password");
      }
    }
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const shortenAddress = (address: string) => {
    return address
      ? `${address.slice(0, 6)}...${address.slice(-4)}`
      : "정보 없음";
  };

  const copyToClipboard = () => {
    if (walletAddress) {
      navigator.clipboard.writeText(walletAddress).then(() => {
        toast.success("지갑 주소가 복사되었습니다!");
      });
    }
  };

  const handleButtonClick = () => {
    if (activityProofLink && role) {
      navigate("/collaboration/manage/myProjects"); // 협업 관리로 이동
    } else {
      navigate("/job/register"); // 협업자 등록으로 이동
    }
  };

  return (
    <div className="flex justify-between items-center p-[30px_16px_21px]">
      <div className="flex flex-col">
        <div className="text-[#212121] text-xs font-semibold font-['Pretendard']">
          안녕하세요
        </div>
        <div className="flex items-center gap-2">
          <div className="text-[#212121] text-xl font-semibold font-['Pretendard']">
            {nickname ? `${nickname}님` : "닉네임을 설정해 주세요."}
          </div>
          {role && (
            <div className="h-[21px] bg-[#b9ff09] rounded-[27px] justify-start items-center inline-flex">
              <div className="px-2 py-1 justify-center items-center gap-2.5 flex">
                <div className="text-[#2b2b2b] text-[11px] font-semibold font-['Pretendard']">
                  {role}
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="w-[109px] h-[25px] px-2 bg-[#f3f3f3] rounded justify-center items-center gap-2 inline-flex mt-2 cursor-pointer"
          onClick={copyToClipboard}
        >
          <div className="text-center text-[#494949] text-[13px] font-semibold font-['Pretendard'] leading-none">
            {walletAddress ? shortenAddress(walletAddress) : "Loading..."}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="w-[79px] h-[30px] p-3 bg-[#212121] justify-center items-center gap-1 inline-flex"
          onClick={handleButtonClick}
        >
          <div className="text-center text-white text-xs font-normal font-['Pretendard']">
            {activityProofLink && role ? "협업 관리" : "협업자 등록"}
          </div>
        </button>
      </div>
    </div>
  );
};

export default MyInfo;
