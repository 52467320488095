import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  applyForProject,
  fetchProjectApplicants,
  fetchProjectById,
} from "../../api/db/projectApi";
import { fetchUserByEmail } from "../../api/db/userApi";
import Modal from "../../components/Modal";
import PageHeader from "../../components/PageHeader";
import SubmitButton from "../../components/SubmitButton";

const ProjectParticipationPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [role, setRole] = useState<string>("");
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalDescription, setModalDescription] = useState<string>("");

  // 로컬스토리지에서 이메일 가져오기
  const email = localStorage.getItem("email") || "";

  // 사용자 정보 가져오기 (role 포함)
  useEffect(() => {
    const fetchUserRole = async () => {
      if (email) {
        try {
          const userData = await fetchUserByEmail(email);
          setRole(userData.role);
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    };

    fetchUserRole();
  }, [email]);

  // 프로젝트 정보 및 참가 여부 가져오기
  useEffect(() => {
    const loadProject = async () => {
      try {
        const projectData = await fetchProjectById(projectId!);
        setProject(projectData);

        // 참여자 리스트에서 현재 사용자가 있는지 확인
        const participants = await fetchProjectApplicants(projectId!);
        const isUserParticipant = participants.some(
          (participant: any) => participant.user.email === email
        );
        setIsParticipant(isUserParticipant); // 이미 참가한 경우 true로 설정

        setLoading(false);
      } catch (error) {
        console.error("Error fetching project or participants:", error);
        setLoading(false);
      }
    };

    if (projectId) {
      loadProject();
    }
  }, [projectId, email]);

  // 프로젝트 참여하기 버튼 클릭 시 동작
  const handleJoinProject = async () => {
    const applicationData = {
      email: email,
      role: role,
    };

    try {
      await applyForProject(projectId!, applicationData);
      setModalTitle("프로젝트 신청이 완료되었습니다.");
      setModalDescription("프로젝트 멤버로 선정되면 협업이 진행됩니다.");
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error confirming application:", error);
      setModalTitle("프로젝트 참여 실패");
      setModalDescription("프로젝트 참여에 실패했습니다.");
      setIsModalOpen(true);
    }
  };

  const formatDate = (date: string) => {
    return format(new Date(date), "yyyy.MM.dd");
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    navigate("/collaboration/manage/myProjects");
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        {/* 상단 헤더 */}
        <PageHeader title="프로젝트 참여" />

        {/* 본문 내용 */}
        <div className="flex-1 overflow-y-auto no-scrollbar p-6">
          <div className="w-[342px] h-[35px] border-b border-[#ff6422] flex items-center gap-2">
            <div className="bg-[#ff6422] rounded-[27px] flex items-center px-2 py-0.5">
              <span className="text-white text-[11px] font-semibold font-['Pretendard']">
                {project?.projectStatus || "상태 없음"}
              </span>
            </div>
            <span className="text-[#7b7b7b] text-xs font-semibold font-['Pretendard']">
              프로젝트 참여하기
            </span>
          </div>
          <div className="mt-6">
            {/* 프로젝트 요약 */}
            <h2 className="text-black text-[15px] font-semibold font-['Pretendard'] mb-2">
              프로젝트 요약
            </h2>
            <div className="w-[342px] bg-white border border-[#dddddd] rounded-[5px] p-4">
              <div className="text-xs font-normal font-['Pretendard'] leading-none">
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">프로젝트명</div>
                  <div className="text-[#212121]">
                    {project?.name || "프로젝트 이름 없음"}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">기간</div>
                  <div className="text-[#212121]">
                    {project?.startDate ? formatDate(project?.startDate) : ""} -{" "}
                    {project?.endDate ? formatDate(project?.endDate) : ""}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">상품 형태</div>
                  <div className="text-[#212121]">{project?.productType}</div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">예상판매량</div>
                  <div className="text-[#212121]">
                    {project?.productQuantity}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">단가</div>
                  <div className="text-[#212121]">
                    {project?.productPrice}원
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">설명</div>
                  <div className="text-[#212121]">{project?.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 하단 버튼 */}
        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text={"이 프로젝트에 참여하기"}
            isDisabled={isParticipant || project?.projectStatus === "취소"}
            onClick={handleJoinProject}
          />
        </div>
      </div>

      {/* 모달 */}
      <Modal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        title={modalTitle}
        description={modalDescription}
      />
    </div>
  );
};

export default ProjectParticipationPage;
