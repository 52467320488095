import React, { useEffect, useState } from "react";
import { Step2Props } from "../../interfaces";
import StepIndicator from "../StepIndicator";

const Step2: React.FC<Step2Props> = ({
  onConfirmParticipants,
  projectSummary,
}) => {
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );

  useEffect(() => {
    onConfirmParticipants(selectedParticipants);
  }, [selectedParticipants, onConfirmParticipants]);

  const handleParticipantSelect = (id: string) => {
    if (selectedParticipants.includes(id)) {
      setSelectedParticipants((prev) =>
        prev.filter((participant) => participant !== id)
      );
    } else {
      setSelectedParticipants((prev) => [...prev, id]);
    }
  };

  // 제안자를 신청자 목록에서 제외
  const filteredApplicants = projectSummary.creator
    ? projectSummary.applications.filter(
        (applicant: any) => applicant.user.id !== projectSummary.creator.id
      )
    : projectSummary.applications;

  return (
    <div className="relative bg-white">
      <StepIndicator stepInfo={"step2"} description="협업팀 구성" />

      <div className="flex justify-between items-center mt-4 gap-2.5">
        <div className="text-gray-600 text-xs font-semibold">프로젝트명</div>
        <div className="flex-grow">
          <div className="text-sm text-gray-500">{projectSummary.name}</div>
        </div>
      </div>

      <div className="mt-[30px]">
        <div className="text-black text-[15px] font-semibold font-['Pretendard']">
          참여자 등록
        </div>
        <div className="mt-2.5 p-4 border rounded-lg">
          <div className="">
            <div className="text-[#858585] text-xs font-semibold font-['Pretendard'] leading-7">
              제안자
            </div>
            {projectSummary.creator && (
              <div className="mt-4 border rounded-lg flex items-center gap-4 h-[54px] p-2 bg-[#ebebeb]">
                <div className="w-10 h-10 relative">
                  {projectSummary.creator.profileImageUrl ? (
                    <img
                      className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      src={projectSummary.creator.profileImageUrl}
                      alt={projectSummary.creator.role}
                      style={{ width: "36px", height: "36px" }}
                    />
                  ) : (
                    <div className="w-[36px] h-[36px] bg-gray-300 rounded-full" />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="text-xs font-bold text-gray-900">
                    {projectSummary.creator.role}
                  </div>
                  <div className="text-sm text-gray-700">
                    {projectSummary.creator.nickname}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8">
            {projectSummary.requiredRoles.map((role: string) => {
              const roleApplicants = filteredApplicants.filter(
                (application: any) => application.role === role
              );

              return (
                <div key={role}>
                  <div className="text-[#858585] text-xs font-semibold font-['Pretendard'] leading-7">
                    {role}
                  </div>
                  {roleApplicants.length === 0 ? (
                    <div className="p-2 mb-2 rounded-lg flex items-center border">
                      <div className="text-sm">참여자가 없습니다.</div>
                    </div>
                  ) : (
                    roleApplicants.map((application: any) => (
                      <div
                        key={application.user.id}
                        className={`p-2 mb-2 rounded-lg flex items-center border cursor-pointer ${
                          selectedParticipants.includes(
                            application.user.id.toString()
                          )
                            ? "bg-[#4400FF] text-white"
                            : "border-gray-300 text-gray-900"
                        }`}
                        onClick={() =>
                          handleParticipantSelect(application.user.id)
                        }
                      >
                        <div className="w-10 h-10 relative">
                          {application.user?.profileImageUrl ? (
                            <img
                              className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2"
                              src={application.user.profileImageUrl}
                              alt={application.role}
                              style={{ width: "36px", height: "36px" }}
                            />
                          ) : (
                            <div className="w-[36px] h-[36px] bg-gray-300 rounded-full" />
                          )}
                        </div>
                        <div className="ml-4 flex flex-col">
                          <div className="text-xs font-bold">
                            {application.role}
                          </div>
                          <div className="text-sm">
                            {application.user.nickname}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
