import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../api/db/projectApi";
import PageHeader from "../../components/PageHeader";
import Step1 from "../../components/ProjectCreate/Step1";
import SubmitButton from "../../components/SubmitButton";

const ProjectIntroductionPage: React.FC = () => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>({});
  const navigate = useNavigate();

  const handleNext = async () => {
    if (isFormValid) {
      try {
        await createProject(projectData);
        navigate("/collaboration/manage/myProjects");
      } catch (error) {
        console.error("프로젝트 생성에 실패했습니다:", error);
      }
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <PageHeader title="프로젝트 생성" />
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <div className="mx-6 my-8">
            <Step1
              setIsFormValid={setIsFormValid}
              setProjectData={setProjectData}
            />
          </div>
          <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
            <SubmitButton
              text="프로젝트 생성"
              isDisabled={!isFormValid}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectIntroductionPage;
