import { ko } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Step1Props } from "../../interfaces";
import LinkInput from "../LinkInput";
import RoleSelection from "../RoleSelection";
import StepIndicator from "../StepIndicator";

const Step1: React.FC<Step1Props> = ({ setIsFormValid, setProjectData }) => {
  const email = localStorage.getItem("email");
  const [projectName, setProjectName] = useState<string>("");
  const [projectDescription, setProjectDescription] = useState<string>("");
  const [communicationChannel, setCommunicationChannel] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string | string[]>([]);
  const [productType, setProductType] = useState<string>("T-shirt");
  const [productQuantity, setProductQuantity] = useState<string>("0");
  const [productPrice, setProductPrice] = useState<string>("0");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isLinkValid, setIsLinkValid] = useState<boolean>(true);

  const productTypes = ["T-shirt", "후드티", "바지"];

  const formatNumberWithComma = (value: string) => {
    const numberValue = parseInt(value.replace(/,/g, ""), 10);
    return isNaN(numberValue)
      ? "0"
      : new Intl.NumberFormat("ko-KR").format(numberValue);
  };

  useEffect(() => {
    const isFormFilled =
      projectName.trim() !== "" &&
      projectDescription.trim() !== "" &&
      isLinkValid &&
      (typeof selectedRole === "string"
        ? selectedRole !== ""
        : selectedRole.length > 0) &&
      productType !== "" &&
      parseInt(productQuantity.replace(/,/g, ""), 10) > 0 &&
      parseInt(productPrice.replace(/,/g, ""), 10) > 0 &&
      startDate !== undefined &&
      endDate !== undefined;

    setIsFormValid(isFormFilled);

    setProjectData({
      creatorEmail: email,
      name: projectName,
      description: projectDescription,
      productType: productType,
      productQuantity: parseInt(productQuantity.replace(/,/g, ""), 10),
      productPrice: parseFloat(productPrice.replace(/,/g, "")),
      communicationChannel: communicationChannel,
      startDate: startDate,
      endDate: endDate,
      requiredRoles: selectedRole,
    });
  }, [
    email,
    projectName,
    projectDescription,
    isLinkValid,
    selectedRole,
    productType,
    productQuantity,
    productPrice,
    startDate,
    endDate,
    communicationChannel,
    setProjectData,
    setIsFormValid,
  ]);

  return (
    <>
      <StepIndicator stepInfo={"step1"} description="프로젝트 소개" />

      {/* 프로젝트명 */}
      <div className="flex flex-col gap-2.5 mt-6">
        <label
          className="text-black text-[15px] font-semibold font-['Pretendard']"
          htmlFor="projectName"
        >
          프로젝트명
        </label>
        <input
          id="projectName"
          type="text"
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
          placeholder="내용을 입력해주세요."
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>

      {/* 소개 */}
      <div className="flex flex-col gap-2.5 mt-[32px]">
        <label
          className="text-black text-[15px] font-semibold font-['Pretendard']"
          htmlFor="projectDescription"
        >
          소개
        </label>
        <textarea
          id="projectDescription"
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
          placeholder="내용을 입력해주세요."
          rows={4}
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
      </div>

      {/* 참여자(중복 선택) */}
      <div className="flex flex-col gap-2.5 mt-10">
        <div className="text-black text-[15px] font-semibold font-['Pretendard']">
          참여자(중복 선택)
        </div>
        <RoleSelection
          onSelectRole={setSelectedRole}
          allowMultipleSelection={true}
        />
      </div>

      {/* 기간 */}
      <div className="flex flex-col gap-6 mt-6">
        <label
          className="text-black text-[15px] font-semibold font-['Pretendard']"
          htmlFor="communicationChannel"
        >
          상세설명
        </label>
        <div className="flex flex-col gap-1">
          <label
            className="text-[#727272] text-[13px] font-semibold font-['Pretendard']"
            htmlFor="projectPeriod"
          >
            기간
          </label>
          <div className="w-[342px] h-[46px] bg-white rounded-[5px] border border-[#dddddd] flex items-center">
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates as [Date, Date];
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              dateFormat="yyyy.MM.dd"
              placeholderText="기간을 선택해주세요."
              locale={ko}
              className="w-full h-full px-3"
              wrapperClassName="w-full"
            />
          </div>
        </div>
      </div>

      {/* 제품 형태 */}
      <div className="flex flex-col gap-1 mt-6">
        <label
          className="text-[#727272] text-[13px] font-semibold font-['Pretendard']"
          htmlFor="productType"
        >
          제품 형태
        </label>
        <select
          id="productType"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
        >
          {productTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* 제품 수량 */}
      <div className="flex flex-col gap-1 mt-6">
        <label
          className="text-[#727272] text-[13px] font-semibold font-['Pretendard']"
          htmlFor="productQuantity"
        >
          제품 수량
        </label>
        <input
          id="productQuantity"
          type="text"
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
          placeholder="0"
          value={productQuantity}
          onChange={(e) =>
            setProductQuantity(formatNumberWithComma(e.target.value))
          }
        />
      </div>

      {/* 제품 단가 */}
      <div className="flex flex-col gap-1 mt-6">
        <label
          className="text-[#727272] text-[13px] font-semibold font-['Pretendard']"
          htmlFor="productPrice"
        >
          제품 단가 (원)
        </label>
        <input
          id="productPrice"
          type="text"
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
          placeholder="0"
          value={productPrice}
          onChange={(e) =>
            setProductPrice(formatNumberWithComma(e.target.value))
          }
        />
      </div>

      {/* 협의채널 */}
      <div className="flex flex-col gap-2.5 mt-6">
        <label
          className="text-black text-[15px] font-semibold font-['Pretendard']"
          htmlFor="communicationChannel"
        >
          협의채널
        </label>
        <LinkInput
          value={communicationChannel}
          placeholder={"링크를 입력해주세요."}
          onChange={(value: string) => setCommunicationChannel(value)}
          className="w-full bg-white rounded-[5px] border border-[#dddddd] p-3 text-[#212121] text-sm font-normal"
          onValidChange={setIsLinkValid}
        />
      </div>
    </>
  );
};

export default Step1;
