import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveMintRequest,
  confirmMintRequest,
  getMintTransaction,
  signMintRequest,
} from "../../api/cw/collaborationApi";
import { getMultisigConfirmations } from "../../api/cw/multisigApi";
import {
  fetchParticipantsByProject,
  fetchProjectById,
  updateParticipantSignedStatus,
  updateProjectConfirmId,
  updateProjectNftImageUrl,
  updateProjectStatus,
  updateProjectTransactionHash,
} from "../../api/db/projectApi";
import PageHeader from "../../components/PageHeader";
import Step4 from "../../components/ProjectCreate/Step4";
import SubmitButton from "../../components/SubmitButton";
import { Project } from "../../interfaces";
import { createNftCollage } from "../../api/db/projectApi";
import { uploadFile, uploadJsonFile } from "../../utils/uploadFile";

const NFTIssuancePage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [participants, setParticipants] = useState<any[]>([]);
  const [projectSummary, setProjectSummary] = useState<Project | null>(null);
  const [userSigned, setUserSigned] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // 프로젝트 및 참여자 데이터 불러오기
  const loadProjectData = async () => {
    if (projectId) {
      const projectData = await fetchProjectById(projectId!);
      setProjectSummary(projectData);
      setParticipants(projectData.participants || []);
    }
  };

  useEffect(() => {
    loadProjectData();
  }, [projectId]);

  // 본인의 서명 상태 확인
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (participants.length > 0 && email) {
      const currentUser = participants.find(
        (participant) => participant.user.email === email
      );
      if (currentUser) {
        setUserSigned(currentUser.signed);
      }
    }
  }, [participants]);

  // 메타데이터 생성 및 JSON 업로드
  const uploadMetadata = async (imageUrl: string) => {
    if (!projectSummary) {
      console.error("프로젝트 정보가 없습니다.");
      return null;
    }

    const revenueSharing = participants
      .map(
        (participant) =>
          `${participant.user.nickname}/${participant.contribution}%`
      )
      .join(", ");

    const participantWalletAddresses = participants.reduce(
      (acc, participant) => {
        acc[participant.user.nickname || `Participant ${participant.user.id}`] =
          participant.user.walletAddress || "N/A";
        return acc;
      },
      {} as Record<string, string>
    );

    const attributes = {
      ...participantWalletAddresses,
      "Product type": projectSummary.productType || "N/A",
      "Product Quantity": projectSummary.productQuantity || "N/A",
      Price: `${projectSummary.productPrice || "N/A"} KRW`,
      Period: `${
        projectSummary.startDate
          ? new Date(projectSummary.startDate).toISOString().slice(0, 10)
          : "N/A"
      } - ${
        projectSummary.endDate
          ? new Date(projectSummary.endDate).toISOString().slice(0, 10)
          : "N/A"
      }`,
      "Revenue sharing": revenueSharing || "N/A",
      "Communication Channel": projectSummary.communicationChannel || "N/A",
    };

    const metadata = {
      attributes: attributes,
      description:
        projectSummary.description ||
        `프로젝트 ${projectSummary.name}의 창작물입니다.`,
      external_url: "https://www.dodress.me",
      image: imageUrl,
      name: `${projectSummary.creator.nickname} #${projectId} ${projectSummary.productType} Collaboration`,
    };

    const uploadedJsonPath = await uploadJsonFile(metadata, `dodress/json/`);
    if (!uploadedJsonPath) {
      console.error("JSON 파일 업로드 실패");
      return null;
    }

    return uploadedJsonPath;
  };
  // URL을 Blob으로 변환하는 함수
  const urlToBlob = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  const createNftCollageHandler = async () => {
    try {
      const nftInfoList = participants.map((participant) => ({
        contractAddress: participant.user.proofOfWorkNftContractAddress,
        tokenId: 1,
      }));
      console.log("nftInfoList", nftInfoList);

      // 콜라주 생성 API 호출
      const response = await createNftCollage({
        nftInfoList, // NFT 리스트
        id: projectId!, // 프로젝트 ID
      });

      if (!response || !response.url) {
        console.error("콜라주 이미지 생성 실패");
        return null;
      }

      const imageUrl = response.url;
      console.log("콜라주 이미지 URL:", imageUrl);

      // URL을 Blob으로 변환
      const blob = await urlToBlob(imageUrl);
      const file = new File([blob], "collage.png", { type: "image/png" });

      // 변환된 Blob을 파일로 업로드
      const uploadedFilePath = await uploadFile(file, "dodress/collage/");
      if (!uploadedFilePath) {
        console.error("파일 업로드 실패");
        return null;
      }

      console.log("업로드된 파일 경로:", uploadedFilePath);

      // 프로젝트의 NFT 이미지 URL 업데이트
      await updateProjectNftImageUrl(projectId!, uploadedFilePath);

      return uploadedFilePath;
    } catch (error) {
      console.error("콜라주 생성 중 오류 발생:", error);
      return null;
    }
  };

  // useEffect(() => {
  //   // participants 배열이 업데이트될 때 handleApproveFailure 호출
  //   if (participants.length > 0) {
  //     handleApproveFailure();
  //   }
  // }, [participants]);

  // approveMintRequest 실패 시 confirmId 초기화 및 signed 상태 재설정
  const handleApproveFailure = async () => {
    try {
      if (projectId && participants) {
        // 모든 참가자가 서명했고 transactionHash가 비어있는지 확인
        const allSigned = participants.every((participant) => {
          return participant.signed;
        });
        const transactionHashExists = projectSummary?.transactionHash;

        if (allSigned && !transactionHashExists) {
          // confirmId를 null로 설정
          await updateProjectConfirmId(projectId, "");

          // 모든 참가자의 signed 상태를 false로 설정
          for (const participant of participants) {
            await updateParticipantSignedStatus(
              projectId,
              participant.user.email,
              false
            );
          }

          console.log(
            "모든 참가자가 서명했으나 transactionHash가 없으므로, Confirm ID 초기화 및 참가자 서명 상태가 재설정되었습니다."
          );
        }
      }
    } catch (error) {
      console.error("Approve 실패 후 처리 중 오류 발생:", error);
    }
  };

  // 서명 처리 함수 수정
  const handleSign = async () => {
    try {
      const email = localStorage.getItem("email");
      if (!email) {
        console.error("이메일을 찾을 수 없습니다.");
        return;
      }

      if (projectId && projectSummary) {
        let confirmId = projectSummary.confirmId || "";

        // 1. 프로젝트에 confirmId가 없다면 발행 요청
        if (!confirmId) {
          const uploadedCollageImageUrl = await createNftCollageHandler();
          if (!uploadedCollageImageUrl) {
            console.error("콜라주 생성 또는 업로드 중 오류 발생");
            return;
          }

          const uploadedJsonPath = await uploadMetadata(
            uploadedCollageImageUrl
          );
          if (!uploadedJsonPath) {
            console.error("메타데이터 업로드 실패");
            return;
          }

          const updatedParticipants = await fetchParticipantsByProject(
            projectId!
          );
          setParticipants(updatedParticipants);

          const currentUser = participants.find(
            (participant) => participant.user.email === email
          );

          if (!currentUser) {
            console.error("사용자를 찾을 수 없습니다.");
            return;
          }

          const toAddress = currentUser.user.walletAddress;
          const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
          const multisigAddress = projectSummary.multisigAddress;
          const tokenUri = uploadedJsonPath;

          const nftAddresses = participants.map(
            (participant) => participant.user.proofOfWorkNftContractAddress
          );

          const mintConfirmResponse = await confirmMintRequest(
            contractAddress!,
            multisigAddress!,
            toAddress,
            tokenUri,
            nftAddresses
          );
          confirmId = mintConfirmResponse.message;
          await updateProjectConfirmId(projectId!, confirmId);
        }

        // 2. 서명 진행
        const signMintRequestResponse = await signMintRequest(confirmId);
        console.log("signMintRequestResponse", signMintRequestResponse);

        await updateParticipantSignedStatus(projectId!, email, true);
        await loadProjectData();

        // 3. 서명 확인
        const multisigAddress = projectSummary.multisigAddress;
        const confirmations = await getMultisigConfirmations(multisigAddress!);
        console.log("confirmations", confirmations);

        // 4. approveMintRequest 처리
        if (confirmations) {
          const approveMintResponse = await approveMintRequest(confirmId);
          console.log("approveMintResponse", approveMintResponse);

          // if (!approveMintResponse || !approveMintResponse.item) {
          //   // 승인 실패 시 처리: confirmId 초기화 및 서명 상태 초기화
          //   await handleApproveFailure();
          //   return;
          // }

          // 승인 성공 시 트랜잭션 해시 업데이트 및 상태 업데이트
          await updateProjectTransactionHash(
            projectId,
            approveMintResponse.item
          );
          await updateProjectStatus(projectId, "진행중");

          const getMintTransactionHash = await getMintTransaction(
            approveMintResponse.item
          );
          console.log("트랜잭션: ", getMintTransactionHash);

          navigate(`/project/nft-issuance/complete/${projectId}`);
        }
      } else {
        console.error("프로젝트 정보가 유효하지 않습니다.");
      }
    } catch (error) {
      console.error("서명 처리 중 오류 발생:", error);
    }
  };

  if (!projectSummary) return <div>Loading...</div>;

  const isProjectCreator =
    projectSummary?.creator.email === localStorage.getItem("email");

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <PageHeader title="프로젝트 협의" />
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <div className="mx-6 my-8">
            <Step4
              projectId={projectId!}
              participants={participants}
              projectSummary={projectSummary}
            />
          </div>
          <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
            <SubmitButton
              text="서명하기"
              isDisabled={userSigned}
              onClick={handleSign}
            />
            {isProjectCreator && (
              <>
                <div className="mt-10 text-[#999999] text-sm font-normal font-['Pretendard'] leading-[18px]">
                  *프로젝트 취소할 경우 진행 중이던 모든 협의가 무산되며, 해당
                  프로젝트를 다시 복구할 수 없습니다.
                </div>
                <div className="flex justify-center mt-4">
                  <div
                    className="text-[#454545] text-sm font-normal font-['Pretendard'] underline cursor-pointer"
                    onClick={async () => {
                      try {
                        await updateProjectStatus(projectId!, "취소");
                        navigate("/collaboration/manage/myProjects");
                      } catch (error) {
                        console.error("프로젝트 취소 중 오류 발생:", error);
                      }
                    }}
                  >
                    프로젝트 취소
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default NFTIssuancePage;
