import { useState } from "react";
import { RoleSelectionProps } from "../interfaces";

const RoleSelection: React.FC<RoleSelectionProps> = ({
  onSelectRole,
  allowMultipleSelection = false,
}) => {
  const roles = [
    { label: "인플루언서", value: "Influencer" },
    { label: "디자이너", value: "Designer" },
    { label: "아티스트", value: "Artist" },
    { label: "생산자", value: "Producer" },
  ];

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const handleRoleChange = (role: string) => {
    if (allowMultipleSelection) {
      // 중복 선택 허용 시 체크박스 동작
      if (selectedRoles.includes(role)) {
        const updatedRoles = selectedRoles.filter((r) => r !== role);
        setSelectedRoles(updatedRoles);
        onSelectRole(updatedRoles);
      } else {
        const updatedRoles = [...selectedRoles, role];
        setSelectedRoles(updatedRoles);
        onSelectRole(updatedRoles);
      }
    } else {
      // 중복 선택 불가 시 라디오 버튼 동작
      setSelectedRoles([role]);
      onSelectRole([role]);
    }
  };

  return (
    <div className="flex flex-col gap-5 my-3">
      {roles.map((role, index) => (
        <div key={index} className="flex items-center">
          <input
            type={allowMultipleSelection ? "checkbox" : "radio"}
            name="role"
            id={`role-${index}`}
            className="w-5 h-5 rounded-full border-2 border-[#070707]"
            checked={selectedRoles.includes(role.value)}
            onChange={() => handleRoleChange(role.value)}
          />
          <label
            htmlFor={`role-${index}`}
            className="ml-2 text-black text-base"
          >
            {role.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RoleSelection;
