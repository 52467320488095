import axios from "axios";

// 모든 프로젝트 가져오기
export const fetchProjects = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects`
  );
  return response.data;
};

// 특정 프로젝트 가져오기 (ID로)
export const fetchProjectById = async (projectId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}`
  );
  return response.data;
};

// 사용자로 프로젝트 가져오기 (사용자가 생성한 프로젝트)
export const fetchProjectsByUser = async (email: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/user/${email}`
  );
  return response.data;
};

// 사용자가 참여한 프로젝트 가져오기
export const fetchProjectsByParticipant = async (email: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/participant/${email}`
  );
  return response.data;
};

// 프로젝트 생성
export const createProject = async (projectData: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects`,
    projectData
  );
  return response.data;
};

// 프로젝트 업데이트
export const updateProject = async (projectId: string, projectData: any) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}`,
    projectData
  );
  return response.data;
};

// 프로젝트 삭제
export const deleteProject = async (projectId: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}`
  );
  return response.data;
};

// 프로젝트 참여자 및 신청자 API

// 특정 프로젝트의 모든 참여자 가져오기
export const fetchParticipantsByProject = async (projectId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/participants`
  );
  return response.data;
};

// 특정 프로젝트에서 특정 참여자 정보 가져오기
export const fetchParticipantById = async (
  projectId: string,
  participantId: string
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/participants/${participantId}`
  );
  return response.data;
};

// 프로젝트에 참여 신청하기
export const applyForProject = async (
  projectId: string,
  applicationData: any
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/apply`,
    applicationData
  );
  return response.data;
};

// 특정 프로젝트 신청자 리스트 가져오기
export const fetchProjectApplicants = async (projectId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/applications`
  );
  return response.data;
};

// 프로젝트 신청 상태 업데이트 (승인/거절)
export const updateApplicationStatus = async (
  projectId: string,
  applicationId: string,
  status: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/applications/${applicationId}`,
    { applicationStatus: status }
  );
  return response.data;
};

// 프로젝트 제안자 정보 가져오기
export const fetchProjectProposer = async (projectId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/proposer`
  );
  return response.data;
};

// 프로젝트 참여자 확정
export const confirmParticipants = async (
  projectId: string,
  selectedParticipants: string[]
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/confirm-participants`,
      { participants: selectedParticipants }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming participants:", error);
    throw error;
  }
};

// 특정 참여자의 기여도 업데이트
export const updateParticipantContribution = async (
  projectId: string,
  participantId: string,
  contribution: number
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/participants/${participantId}/contribution`,
    { contribution }
  );
  return response.data;
};

// 멀티시그 주소 업데이트
export const updateMultisigAddress = async (
  projectId: string,
  multisigAddress: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/multisig`,
    { multisigAddress }
  );
  return response.data;
};

// 특정 참여자의 서명 상태 업데이트
export const updateParticipantSignedStatus = async (
  projectId: string,
  email: string,
  signed: boolean
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/participants/${email}/signed`,
      { signed }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating signed status:", error);
    throw error;
  }
};

// 프로젝트 상태 업데이트
export const updateProjectStatus = async (
  projectId: string,
  projectStatus: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/status`,
      {
        projectStatus,
      }
    );
    return response.data;
  } catch (error) {
    console.error("프로젝트 상태 업데이트 중 오류 발생:", error);
    throw error;
  }
};

// confirmId 업데이트
export const updateProjectConfirmId = async (
  projectId: string,
  confirmId: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/confirmId`,
      { confirmId }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating confirmId:", error);
    throw error;
  }
};

// transactionHash 업데이트
export const updateProjectTransactionHash = async (
  projectId: string,
  transactionHash: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/transactionHash`,
      { transactionHash }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating transactionHash:", error);
    throw error;
  }
};

// 프로젝트 NFT 이미지 URL 업데이트
export const updateProjectNftImageUrl = async (
  projectId: string,
  nftImageUrl: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/nft-image`,
      { nftImageUrl }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating nftImageUrl:", error);
    throw error;
  }
};

// NFT 콜라주 생성 API 호출 함수 추가
export const createNftCollage = async (nftData: {
  nftInfoList: any[];
  id: string;
}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/projects/generate-collage`,
      nftData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating NFT collage:", error);
    throw error;
  }
};
