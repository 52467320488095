import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkSignupStatus,
  sendSignupEmailCode,
  signup,
  verifyEmailCode,
} from "../../api/cw/authApi";
import { createUser } from "../../api/db/userApi";
import Modal from "../../components/Modal";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // 약관 동의 상태 관리
  const [overage, setOverage] = useState(false);
  const [collect, setCollect] = useState(false);
  const [thirdparty, setThirdparty] = useState(false);
  const [advertise, setAdvertise] = useState(false);

  const [emailError, setEmailError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // 이메일 형식 검사 정규식
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // 이메일 중복 확인 후 인증 코드 발송 처리
  const handleSendCode = async () => {
    if (!isValidEmail(email)) {
      setEmailError("유효한 이메일 주소를 입력하세요.");
      return;
    }

    try {
      await checkSignupStatus(email);

      // 중복된 이메일이 없으면 인증 코드 발송
      await sendSignupEmailCode(email);
      setIsCodeSent(true);
      setEmailError(null);
      console.log("인증 코드 발송 성공");
    } catch (error) {
      if (error instanceof Error) {
        setEmailError(error.message);
      } else {
        console.error("인증 코드 발송 실패:", error);
      }
    }
  };

  // 인증 코드 검증
  const handleVerifyCode = async () => {
    try {
      const response = await verifyEmailCode(email, verificationCode);

      if (response && response.status === 200) {
        console.log("인증 코드 검증 성공");
        setIsCodeVerified(true);
        setErrorMessage(null);
      }
    } catch (error: any) {
      if (error.response?.status === 400 || error.response?.status === 404) {
        const errorMsg = error.response?.data?.msg || "인증 코드 검증 실패";
        setErrorMessage(errorMsg);
      } else {
        console.error("인증 코드 검증 실패:", error);
      }
    }
  };

  // 회원가입 처리
  const handleRegister = async () => {
    // 비밀번호 불일치 시 오류 메시지 설정
    if (password !== confirmPassword) {
      setErrorMessage("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      // 회원가입 요청
      await signup(
        email,
        password,
        verificationCode,
        overage,
        collect,
        thirdparty,
        advertise
      );
      console.log("회원가입 성공");

      // 회원 정보 저장
      await createUser(email, password);
      console.log("사용자 생성 성공");

      setIsModalOpen(true);
    } catch (error) {
      console.error("회원가입 실패:", error);
    }
  };

  // 회원가입 버튼 활성화 여부 확인
  const isRegisterEnabled = () => {
    return (
      isCodeVerified &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword &&
      overage &&
      collect &&
      thirdparty
    );
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
        <div className="flex items-center justify-center mb-8">
          <img
            src="../../assets/images/logo.svg"
            alt="ABC Wallet"
            className="w-[120px]"
          />
        </div>

        <div className="mb-6">
          <input
            type="email"
            placeholder="이메일"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsCodeSent(false);
              setIsCodeVerified(false);
              setErrorMessage(null);
            }}
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
          {emailError && <div className="text-red-500 mb-4">{emailError}</div>}

          <button
            onClick={handleSendCode}
            className="w-full bg-gray-200 hover:bg-gray-300 text-black py-3 rounded-lg font-semibold transition duration-300"
          >
            인증 코드 발송
          </button>
        </div>

        {isCodeSent && (
          <div className="mb-6">
            <input
              type="text"
              placeholder="인증 코드"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            />
            <button
              onClick={handleVerifyCode}
              className="w-full bg-gray-200 hover:bg-gray-300 text-black py-3 rounded-lg font-semibold transition duration-300"
            >
              인증 코드 확인
            </button>
            {errorMessage && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
          </div>
        )}

        {isCodeVerified && (
          <div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              />
              <input
                type="password"
                placeholder="비밀번호 확인"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-3 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              />
              {errorMessage && (
                <div className="text-red-500 mt-2">{errorMessage}</div>
              )}
            </div>

            {/* 약관 동의 섹션 */}
            <div className="mb-6">
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={overage}
                  onChange={() => setOverage(!overage)}
                />
                <div className="inline ml-2">14세 이상입니다. (필수)</div>
              </label>
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={collect}
                  onChange={() => setCollect(!collect)}
                />
                <div className="inline ml-2">
                  개인정보 수집 및 이용에 동의합니다. (필수)
                </div>
              </label>
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={thirdparty}
                  onChange={() => setThirdparty(!thirdparty)}
                />
                <div className="inline ml-2">
                  제3자 제공에 동의합니다. (필수)
                </div>
              </label>
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={advertise}
                  onChange={() => setAdvertise(!advertise)}
                />
                <div className="inline ml-2">
                  광고성 정보 수신에 동의합니다. (선택)
                </div>
              </label>
            </div>

            {/* 회원가입 버튼 */}
            <button
              onClick={handleRegister}
              disabled={!isRegisterEnabled()}
              className={`w-full py-3 rounded-lg font-semibold transition duration-300 ${
                isRegisterEnabled()
                  ? "bg-blue-500 hover:bg-blue-700 text-white"
                  : "bg-gray-400 text-white cursor-not-allowed"
              }`}
            >
              회원가입
            </button>
          </div>
        )}
      </div>

      {/* 모달 */}
      <Modal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        title="회원가입 완료"
        description="회원가입이 완료되었습니다. 로그인 후 서비스를 이용해주세요."
      />
    </div>
  );
}

export default RegisterPage;
