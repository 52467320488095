import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeaderProps } from "../interfaces";

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  leftIcon,
  rightIcon,
}) => {
  const navigate = useNavigate();

  return (
    <div className="mt-10 w-[390px] h-12 px-4 py-2 border-b border-[#f1f1f3] flex items-center  bg-white">
      {/* 왼쪽 아이콘 */}
      <div
        className="flex justify-start items-center cursor-pointer"
        onClick={() => navigate(-1)}
        style={{ flexBasis: "33.33%" }}
      >
        {leftIcon ? (
          leftIcon
        ) : (
          <div className="w-6 h-6 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 5V6.74999L9.75004 12H8.00005L15 5ZM9.75004 12.0017H8.00005L15 19.0017V17.2517L9.75004 12.0017Z"
                fill="#212121"
              />
            </svg>
          </div>
        )}
      </div>

      {/* 타이틀 */}
      <div
        className="text-center text-[#212121] text-base font-normal font-['Pretendard']"
        style={{ flexBasis: "33.33%" }}
      >
        {title}
      </div>

      {/* 오른쪽 아이콘 (로그아웃 버튼 삭제됨) */}
      <div
        className="flex justify-end items-center gap-2"
        style={{ flexBasis: "33.33%" }}
      >
        {rightIcon && rightIcon}
        {/* 로그아웃 아이콘을 렌더링하지 않음 */}
      </div>
    </div>
  );
};

export default PageHeader;
