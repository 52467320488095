import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProjectById } from "../../api/db/projectApi";
import PageHeader from "../../components/PageHeader";
import SubmitButton from "../../components/SubmitButton";

const ProjectDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // 프로젝트 정보 불러오기
  useEffect(() => {
    const getProject = async () => {
      try {
        if (projectId) {
          const projectData = await fetchProjectById(projectId);
          setProject(projectData);
          setLoading(false);
        }
      } catch (error) {
        console.error("프로젝트 정보를 가져오는 중 오류 발생:", error);
        setLoading(false);
      }
    };

    getProject();
  }, [projectId]);

  // 날짜 형식 변환 함수
  const formatDate = (date: string) => {
    return format(new Date(date), "yyyy.MM.dd");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>로딩 중...</div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>프로젝트 정보를 찾을 수 없습니다.</div>
      </div>
    );
  }

  // 참여자 목록을 쉼표로 구분된 문자열로 변환
  const participantsString =
    project.participants && project.participants.length > 0
      ? project.participants
          .filter(
            (participant: { user: { nickname: string } }) =>
              participant.user.nickname !== project.creator.nickname
          )
          .map(
            (participant: { user: { nickname: string } }) =>
              participant.user.nickname
          )
          .join(", ")
      : "참여자 없음";

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        {/* 상단 헤더 */}
        <PageHeader title="프로젝트 정보" />

        {/* 본문 내용 */}
        <div className="flex-1 overflow-y-auto no-scrollbar p-6">
          <div className="w-[342px] h-[35px] border-b border-[#ff6422] flex items-center gap-2">
            <div className="bg-[#ff6422] rounded-[27px] flex items-center px-2 py-0.5">
              <span className="text-white text-[11px] font-semibold font-['Pretendard']">
                {project?.projectStatus || "상태 없음"}
              </span>
            </div>
            <span className="text-[#7b7b7b] text-xs font-semibold font-['Pretendard']">
              프로젝트 정보
            </span>
          </div>
          <div className="mt-6">
            {/* 프로젝트 요약 */}
            <h2 className="text-black text-[15px] font-semibold font-['Pretendard'] mb-2">
              프로젝트 요약
            </h2>
            <div className="w-[342px] bg-white border border-[#dddddd] rounded-[5px] p-4">
              <div className="text-xs font-normal font-['Pretendard'] leading-none">
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">프로젝트명</div>
                  <div className="text-[#212121]">
                    {project?.name || "프로젝트 이름 없음"}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">기간</div>
                  <div className="text-[#212121]">
                    {project?.startDate ? formatDate(project?.startDate) : ""} -{" "}
                    {project?.endDate ? formatDate(project?.endDate) : ""}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">상품 형태</div>
                  <div className="text-[#212121]">{project?.productType}</div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">예상 판매량</div>
                  <div className="text-[#212121]">
                    {project?.productQuantity}
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">단가</div>
                  <div className="text-[#212121]">
                    {project?.productPrice}원
                  </div>
                </div>
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">설명</div>
                  <div className="text-[#212121]">{project?.description}</div>
                </div>

                {/* 제안자 정보 */}
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">제안자</div>
                  <div className="text-[#212121]">
                    {project?.creator?.nickname || "제안자 없음"}
                  </div>
                </div>

                {/* 참여자 목록 */}
                <div className="mb-4 flex justify-between">
                  <div className="text-[#797979]">참여자</div>
                  <div className="text-[#212121]">{participantsString}</div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-[11px]">
              <div className="w-[136px] h-[30px] p-3 bg-[#212121] justify-center items-center gap-1 inline-flex">
                <div
                  className="text-center text-white text-xs font-normal font-['Pretendard'] cursor-pointer"
                  onClick={() =>
                    navigate(`/project/nft-issuance/complete/${projectId}`)
                  }
                >
                  Collab-NFT 확인하기
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 하단 버튼 */}
        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text={"확인"}
            isDisabled={false}
            link={"/collaboration/manage/myProjects"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
