import React from "react";
import { StepIndicatorProps } from "../interfaces";

const StepIndicator: React.FC<StepIndicatorProps> = ({
  stepInfo,
  description,
}) => {
  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-[342px] h-[35px] border-b border-[#ff6422] justify-start items-center gap-2 inline-flex">
        <div className="bg-[#ff6422] rounded-[27px] justify-start items-center flex">
          <div className="px-1.5 py-0.5 justify-center items-center gap-2.5 flex">
            <div className="text-white text-[11px] font-semibold font-['Pretendard']">
              {stepInfo}
            </div>
          </div>
        </div>
        <div className="text-[#7b7b7b] text-xs font-semibold font-['Pretendard'] leading-7">
          {description}
        </div>
      </div>
    </div>
  );
};

export default StepIndicator;
