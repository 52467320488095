import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchProjectById,
  updateParticipantContribution,
} from "../../api/db/projectApi";
import PageHeader from "../../components/PageHeader";
import Step3 from "../../components/ProjectCreate/Step3";
import SubmitButton from "../../components/SubmitButton";
import { Project, ProjectParticipant } from "../../interfaces";

const ProjectAgreementPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [participants, setParticipants] = useState<ProjectParticipant[]>([]);
  const [projectInfo, setProjectInfo] = useState<Project | null>(null);

  // 데이터를 로드하는 함수
  const loadProjectData = async () => {
    try {
      if (projectId) {
        // fetchProjectById로 프로젝트 정보와 참여자 정보 가져오기
        const fetchedProjectInfo = await fetchProjectById(projectId);
        // 상태 업데이트
        setProjectInfo(fetchedProjectInfo);
        setParticipants(fetchedProjectInfo.participants || []); // 참여자 정보 업데이트
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const handleConfirmParticipants = async () => {
    try {
      if (!projectInfo) return;

      // 모든 참여자의 기여도를 서버로 업데이트
      for (const participant of participants) {
        await updateParticipantContribution(
          projectInfo.id,
          participant.id,
          participant.contribution
        );
      }

      console.log("All contributions updated successfully");
      navigate(`/project/nft-issuance/${projectId}`);
    } catch (error) {
      console.error("Error confirming participants:", error);
    }
  };

  useEffect(() => {
    loadProjectData();
  }, [projectId]);

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative flex flex-col h-full">
        <PageHeader title="프로젝트 생성" />
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <div className="mx-6 my-8">
            {projectInfo && (
              <Step3
                setIsFormValid={setIsFormValid}
                participants={participants}
                setParticipants={setParticipants}
                projectInfo={projectInfo}
                setProjectInfo={setProjectInfo}
              />
            )}
          </div>
        </div>

        {/* 하단 버튼 */}
        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton
            text="저장"
            isDisabled={!isFormValid}
            onClick={handleConfirmParticipants}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectAgreementPage;
