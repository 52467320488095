import axios from "axios";
import { ec as EC } from "elliptic";

const ec = new EC("p256");

// Secure Channel 생성 함수
export async function securechannel() {
  // 랜덤 문자열 생성 함수
  function generateRandomString(length = 10) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  // 랜덤 문자열로 개인 키 생성
  const plaintext = generateRandomString();
  const mykey = ec.keyFromPrivate(plaintext, "hex");
  const pubPoint = mykey.getPublic();
  const pubkey = pubPoint.encode("hex", false); // 공개키 생성

  // 요청 바디 설정
  const requestBody = {
    pubkey: pubkey,
    plain: plaintext,
  };

  try {
    // Secure channel 생성 요청
    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/secure-channel`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json", // JSON 형식으로 요청
        },
      }
    );

    const { channelid, encrypted, publickey } = response.data.item;

    // 성공 시 데이터 반환
    return { channelid, encrypted, publickey, mykey, plaintext };
  } catch (error: any) {
    if (error.response) {
      console.error("Error Response:", error.response.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
}
