import { ec as EC } from "elliptic";
import CryptoJS from "crypto-js";

const ec = new EC("p256");

const createSecretKey = (publicKey: string, mykey: EC.KeyPair) => {
  const serverKey = ec.keyFromPublic(publicKey, "hex");
  const sharedSecret = serverKey.getPublic().mul(mykey.getPrivate()).getX();
  let secretKey = sharedSecret.toString(16);
  if (secretKey.length === 63) {
    secretKey = `0${secretKey}`;
  }
  return secretKey.padStart(64, "0");
};

const decrypt = (secretKey: string, encrypted: string) => {
  const key = CryptoJS.enc.Hex.parse(secretKey.substring(0, 32));
  const iv = CryptoJS.enc.Hex.parse(secretKey.substring(32));
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const encrypt = (secretKey: string, text: string) => {
  const key = CryptoJS.enc.Hex.parse(secretKey.substring(0, 32));
  const iv = CryptoJS.enc.Hex.parse(secretKey.substring(32));
  return CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).toString();
};

type SecureChannelResult = {
  channelid: string;
  encrypted: string;
  publickey: string;
  mykey: any;
  plaintext: string;
};

export async function securepassword(
  text: string,
  secureChannelResult: SecureChannelResult
) {
  const { channelid, encrypted, publickey, mykey, plaintext } =
    secureChannelResult;
  const secretKey = createSecretKey(publickey, mykey);

  const decryptedText = decrypt(secretKey, encrypted);

  if (decryptedText !== plaintext) {
    throw new Error("Decryption failed");
  }

  const encryptedText = encrypt(secretKey, text);
  return {
    encrypted: encryptedText,
    channelid,
  };
}
