import React from "react";
import { useNavigate } from "react-router-dom";
import { Project, ProjectParticipant } from "../../interfaces";
import StepIndicator from "../StepIndicator";

const shortenWalletAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const ParticipantCard: React.FC<ProjectParticipant & { isLast?: boolean }> = ({
  role,
  user,
  contribution,
  signed,
  isLast = false,
}) => (
  <div
    className={`flex justify-between items-center pl-3 py-2 ${
      !isLast ? "border-b border-[#dddddd]" : ""
    }`}
  >
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="text-[#8d8d8d] text-xs font-medium">{role} |</span>
        <span className="text-[#212121] text-sm font-normal">
          {user.nickname}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-[#8d8d8d] text-xs font-medium">기여도 |</span>
        <span className="text-[#212121] text-sm font-normal">
          {contribution}%
        </span>
      </div>
      <div className="text-[#898989] text-sm font-medium break-all">
        {shortenWalletAddress(user.walletAddress || "")}
      </div>
    </div>
    <div className="p-1 justify-start items-center gap-0.5 flex">
      <div
        className={`px-1.5 py-[3px] rounded-xl flex items-center ${
          signed === false ? "bg-[#9e9e9e]" : "bg-[#ff5e5e]"
        }`}
      >
        <span className="text-white text-[11px] font-semibold">
          {signed === false ? "대기중" : "서명완료"}
        </span>
      </div>
    </div>
  </div>
);

const Step4: React.FC<{
  projectId: string;
  projectSummary: Project;
  participants: ProjectParticipant[];
}> = ({ projectId, projectSummary, participants }) => {
  const navigate = useNavigate();
  const explorerUrl = process.env.REACT_APP_EXPLORER_URL;

  return (
    <div className="relative bg-white">
      <StepIndicator stepInfo={"협의중"} description="Collaboration NFT 발행" />

      {/* 프로젝트 요약 */}
      <div className="flex flex-col gap-4 mt-6">
        <div className="text-black text-[15px] font-semibold">
          프로젝트 요약
        </div>

        <div className="w-[342px] bg-white rounded-[5px] border border-[#dddddd] p-4">
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              프로젝트명
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {projectSummary.name}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              지갑주소
            </div>
            <div
              className="w-[206px] text-[#212121] text-[13px] font-normal break-all underline cursor-pointer"
              onClick={() =>
                window.open(
                  `${explorerUrl}/address/${projectSummary.multisigAddress}`,
                  "_blank"
                )
              }
            >
              {shortenWalletAddress(projectSummary.multisigAddress || "")}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              기간
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {new Date(projectSummary.startDate!).toLocaleDateString()} -{" "}
              {new Date(projectSummary.endDate!).toLocaleDateString()}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              상품 형태
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {projectSummary.productType}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              예상 판매량
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {projectSummary.productQuantity}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              단가
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {projectSummary.productPrice}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-[105px] text-[#797979] text-xs font-normal">
              설명
            </div>
            <div className="w-[206px] text-[#212121] text-[13px] font-normal">
              {projectSummary.description}
            </div>
          </div>
        </div>

        <div
          className="flex justify-center"
          onClick={() => {
            if (projectSummary.communicationChannel) {
              let url = projectSummary.communicationChannel;

              if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
              }

              window.open(url, "_blank");
            }
          }}
        >
          <div className="w-[136px] h-[30px] bg-[#212121] flex items-center justify-center text-white text-xs font-normal cursor-pointer">
            커뮤니티 채널 바로가기
          </div>
        </div>
      </div>

      {/* 참여자 리스트 */}
      <div className="mt-[52px] flex flex-col gap-2.5">
        <div className="flex justify-between items-center w-[342px]">
          <span className="text-black text-[15px] font-semibold">참여자</span>
          <div
            className="px-2 py-1.5 border border-[#a8a8a8] text-[#585858] text-xs font-semibold rounded cursor-pointer"
            onClick={() => navigate(`/project/agreement/${projectId}`)}
          >
            기여도 수정
          </div>
        </div>

        <div className="w-[342px] border border-[#dddddd] rounded-[5px] px-2.5 py-1">
          {participants.map((participant, index) => (
            <ParticipantCard
              key={index}
              {...participant}
              isLast={index === participants.length - 1}
            />
          ))}
        </div>

        <div className="w-[342px] text-[#999999] text-sm mt-8">
          *수정 시 모든 서명이 취소되며, 모든 참여자의 서명이 새로 진행됩니다.
          <br />
          *모든 참여자의 서명이 완료되면 자동으로 Collab-NFT가 발생됩니다.
        </div>
      </div>
    </div>
  );
};

export default Step4;
