import React from "react";

const Header: React.FC = () => {
  return (
    <div className="mt-10 px-4 py-2 flex justify-between items-center">
      <div className="p-2 flex justify-center items-center gap-2">
        <img
          className="w-[60px] h-5"
          src="/assets/images/logo.svg"
          alt="logo"
        />
      </div>
      <div className="flex items-center gap-2.5">
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5559 2L14.2225 3.66667H9.7781L11.4448 2H12.5559ZM11.4451 4.22217L11.445 4.22222H12.5559H14.7781L16.4448 5.88889L18.667 8.11111V10.3333L18.6667 10.333V15.8887L20.3333 17.5553V19.222H17.001L17.0007 19.2223H11.4451H7.00065L7.00033 19.222V19.2222H3.66699V17.5556L5.33366 15.8889H5.33398V10.3333V8.11106V8.111L7.00065 6.44434V6.44439L7.55615 5.88889H7.55588L9.22255 4.22222H9.22282L9.22287 4.22217H11.4451ZM17 17.5557V15.8887V8.66634L14.2225 5.88889H9.77838L7.00065 8.66661V17.5557H13.1118H17ZM9.22255 20.3333L10.8892 22H13.1114L14.7781 20.3333H9.22255Z"
              fill="#221815"
            />
          </svg>
          <div className="w-[7px] h-[7px] bg-[#ff3c12] rounded-full border border-white absolute top-0 right-0" />
        </div>
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11 0.5H5L3.5 2V5.5H1.5H0V7V18L1.5 19.5H14.5L16 18V7V5.5H14.5H12.5V2L11 0.5ZM11 7V9H12.5V7H14.5V18H1.5V7H3.5V9H5V7H11ZM11 5.5V2H5V5.5H11Z"
              fill="#241E1B"
            />
          </svg>

          <div className="w-2.5 h-2.5 bg-[#ff3c12] rounded-full absolute top-0 right-0">
            <div className="text-center text-white text-[6px] leading-[7px]">
              4
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
