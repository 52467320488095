import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createMultisigWallet } from "../../api/cw/multisigApi";
import {
  fetchProjectById,
  updateMultisigAddress,
  updateProjectStatus,
  confirmParticipants,
} from "../../api/db/projectApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "../../components/Modal";
import PageHeader from "../../components/PageHeader";
import Step2 from "../../components/ProjectCreate/Step2";
import SubmitButton from "../../components/SubmitButton";

const CollaborationTeamPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );
  const [projectSummary, setProjectSummary] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  // 프로젝트 및 참여자 데이터 가져오기
  useEffect(() => {
    const loadProjectData = async () => {
      if (projectId) {
        const projectData = await fetchProjectById(projectId); // 프로젝트 전체 정보 한 번에 가져오기
        setProjectSummary(projectData); // 프로젝트 요약 정보 상태에 저장
      }
    };
    loadProjectData();
  }, [projectId]);

  const handleConfirmParticipants = async () => {
    try {
      if (projectId && projectSummary) {
        setIsLoading(true); // 로딩 상태 시작

        // 선택된 참여자들의 walletAddress 추출
        const selectedWalletAddresses = projectSummary.applications
          .filter((application: any) =>
            selectedParticipants.includes(application.user.id.toString())
          )
          .map((application: any) => application.user.walletAddress);

        // 제안자의 walletAddress 추가
        const proposerWalletAddress = projectSummary.creator.walletAddress;

        // 선택된 참여자들과 제안자의 walletAddress를 합침
        const walletAddresses = [
          ...selectedWalletAddresses,
          proposerWalletAddress,
        ];

        console.log("walletAddresses", walletAddresses);

        // 선택된 참여자 ID에 제안자 추가
        const allParticipants = [
          ...selectedParticipants,
          projectSummary.creator.id,
        ];

        await confirmParticipants(projectId, allParticipants);

        // 멀티시그 지갑 생성
        const createMultisigWalletResponse = await createMultisigWallet(
          walletAddresses
        );
        console.log(
          "createMultisigWalletResponse",
          createMultisigWalletResponse
        );

        // 멀티시그 지갑 생성이 성공한 경우에만 멀티시그 주소 업데이트
        if (
          createMultisigWalletResponse &&
          createMultisigWalletResponse.status === 200
        ) {
          // 제안자와 선택된 참여자 전달
          // await confirmParticipants(projectId, allParticipants);

          await updateMultisigAddress(
            projectId,
            createMultisigWalletResponse.item.multisigAddress
          );

          // 프로젝트 상태를 "협의중"으로 업데이트
          await updateProjectStatus(projectId, "협의중");

          // 협의 페이지로 이동
          navigate(`/project/agreement/${projectId}`);
        } else {
          console.error("멀티시그 지갑 생성에 실패했습니다.");
        }
      } else {
        console.error("projectId 또는 프로젝트 데이터가 유효하지 않습니다.");
      }
    } catch (error) {
      console.error("참여자 확정 중 오류 발생:", error);
    } finally {
      setIsLoading(false); // 로딩 상태 종료
    }
  };

  const handleNext = () => {
    if (selectedParticipants.length > 0) {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  const handleModalConfirm = async () => {
    setIsModalOpen(false); // 모달 닫기
    await handleConfirmParticipants(); // 참여자 확정 및 멀티시그 지갑 생성
  };

  if (!projectSummary) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <PageHeader title="프로젝트 생성" />
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <div className="mx-6 my-8">
            <Step2
              onConfirmParticipants={setSelectedParticipants}
              projectSummary={projectSummary}
            />
          </div>

          {/* 로딩 중일 때 스피너 표시 */}
          {isLoading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
              <LoadingSpinner />
            </div>
          )}

          {/* 모달 */}
          <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onConfirm={handleModalConfirm}
            title="프로젝트 지갑을 생성할까요?"
            description="지갑 생성 이후에 참여자 변경을 하려면 모든 참여자의 동의 서명이 필요합니다."
          />

          <div
            className={`${
              isModalOpen ? "" : "sticky"
            } bottom-0 z-10 w-full px-[29px] py-4 bg-white`}
          >
            <SubmitButton
              text="참여자 확정"
              isDisabled={selectedParticipants.length === 0 || isLoading}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationTeamPage;
