import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MenuItems from "../../components/MenuItems";
import MyInfo from "../../components/MyInfo";

function MyProfile() {
  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col">
        <Header />
        <div className="flex-1 overflow-y-auto no-scrollbar">
          <MyInfo />
          <MenuItems />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default MyProfile;
