import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserByEmail } from "../../api/db/userApi";
import SubmitButton from "../../components/SubmitButton";

const CollaborationRegisterComplete: React.FC = () => {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [profileImageUrl, setProfileImageUrl] = useState<string>("");
  const [proofOfWorkNftContractAddress, setProofOfWorkNftContractAddress] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchUserData = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      try {
        const user = await fetchUserByEmail(email);
        setNickname(user.nickname || "Unknown User");
        setRole(user.role || "Unknown Role");
        setProfileImageUrl(user.profileImageUrl);
        setProofOfWorkNftContractAddress(user.proofOfWorkNftContractAddress);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    navigate("/collaboration/team");
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 h-screen">
      <div className="w-full max-w-[390px] bg-white relative h-full flex flex-col ">
        <div className="w-[390px] h-10 pl-[25px] pr-[15px] py-[11px]"></div>

        <div className="flex-1 px-[24px] py-[38px] overflow-y-auto no-scrollbar">
          <div className="px-[7px] mb-6">
            <h1 className="text-[#212121] text-xl font-semibold">
              {nickname}님
            </h1>
            <div className="mt-4 text-[#212121] text-sm font-semibold font-['Pretendard']">
              인증이 완료되었습니다. 협업을 시작해보세요!
            </div>
          </div>
          <div className="w-[342px] px-[15px] py-7 bg-[#f6f6f6] flex flex-col justify-start items-center gap-6">
            {/* Badge Section */}
            <img
              className="w-[231.52px] h-[231.52px]"
              src={profileImageUrl}
              alt="badge"
            />

            {/* User Info Section */}
            <div className="flex flex-col items-center gap-1">
              <div className="text-black text-xl font-bold font-['Pretendard']">
                {nickname}
              </div>
              <div className="text-[#999999] text-sm font-bold font-['Pretendard']">
                {role} {/* 역할 표시 */}
              </div>
            </div>

            {/* Badge Description */}
            <div
              onClick={handleSubmit}
              className="w-[284px] text-center text-black text-base font-bold font-['Pretendard'] leading-normal"
            >
              doDRESS의 {role}임을 인증합니다. 이 Badge의 소유자는 doDRESS의
              프로젝트에 자유롭게 참여할 수 있습니다.
            </div>

            {/* View Details Link */}
            <div className="text-[#454545] text-sm font-normal font-['Pretendard'] underline">
              <a
                href={`${process.env.REACT_APP_EXPLORER_URL}/address/${proofOfWorkNftContractAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                등록 정보 보러가기
              </a>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 z-10 w-full px-[29px] py-4 bg-white">
          <SubmitButton text="확인" isDisabled={false} link={"/mypage"} />
          <div className="h-6 flex-col justify-start items-center flex"></div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationRegisterComplete;
