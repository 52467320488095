import axios from "axios";
import { securechannel } from "../cw/securechannel";
import { securepassword } from "../cw/securepassword";
import api from "./api";

// 로그인 API 호출 함수
export const login = async (email: string, password: string) => {
  try {
    const secureChannelResult = await securechannel();
    const secureResult = await securepassword(password, secureChannelResult);
    const encryptedPassword = secureResult.encrypted;

    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/auth/login`,
      {
        email: email,
        password: encryptedPassword,
      },
      {
        headers: {
          "Secure-Channel": secureChannelResult.channelid,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = response.data;
      localStorage.setItem("email", email);
      localStorage.setItem("access_token", data.item.access_token);
      localStorage.setItem("refresh_token", data.item.refresh_token);
      sessionStorage.setItem("password", password);
      return {
        status: data.status,
        message: data.message,
        item: data.item,
      };
    } else {
      return { message: "Login failed", status: response.status };
    }
  } catch (error) {
    console.error("Login error:", error);
    throw new Error("Internal server error");
  }
};

// 인증 토큰 갱신 API 호출 함수
export const reissueToken = async (refreshToken: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/auth/reissue`,
      {
        refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = response.data;
      return {
        status: data.status,
        message: data.message,
        item: data.item,
      };
    } else {
      return { message: "Token reissue failed", status: response.status };
    }
  } catch (error) {
    console.error("Token reissue error:", error);
    throw new Error("Internal server error");
  }
};

// 회원가입 이메일 인증 코드 발송
export const sendSignupEmailCode = async (email: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/user/mail/sendcode/${email}`
    );

    if (response.status === 200) {
      return { message: "Email sent successfully", status: response.status };
    } else {
      return { message: "Email send failed", status: response.status };
    }
  } catch (error) {
    console.error("Email send error:", error);
    throw new Error("Internal server error");
  }
};

// 회원가입 이메일 인증 코드 검증
export const verifyEmailCode = async (email: string, code: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/user/mail/sendcode/verify/${email}?code=${code}`
    );

    if (response.status === 200) {
      return {
        message: "Email verification successful",
        status: response.status,
      };
    } else {
      return { message: "Email verification failed", status: response.status };
    }
  } catch (error) {
    console.error("Email verification error:", error);
    throw new Error("Internal server error");
  }
};

// 회원가입 API 호출 함수
export const signup = async (
  email: string,
  password: string,
  code: string,
  fourteenYearsAgeOlder: boolean,
  termsConditions: boolean,
  privateInformationAgree: boolean,
  marketingAgree: boolean
) => {
  try {
    const secureChannelResult = await securechannel();
    const secureResult = await securepassword(password, secureChannelResult);
    const encryptedPassword = secureResult.encrypted;

    const response = await axios.post(
      `${process.env.REACT_APP_SB_URL}/user/sign-up`,
      {
        email,
        encryptPassword: encryptedPassword,
        code,
        fourteenYearsAgeOlder,
        termsConditions,
        privateInformationAgree,
        marketingAgree,
      },
      {
        headers: {
          "Secure-Channel": secureChannelResult.channelid,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = response.data;
      return {
        status: data.status,
        message: data.message,
        item: data.item,
      };
    } else {
      return { message: "Signup failed", status: response.status };
    }
  } catch (error) {
    console.error("Signup error:", error);
    throw new Error("Internal server error");
  }
};

// 회원가입 여부 확인 API 호출 함수
export const checkSignupStatus = async (email: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SB_URL}/user/check/${email}`
    );

    if (response.status === 200) {
      return { message: "Email check successful", status: response.status };
    } else {
      return { message: "Email check failed", status: response.status };
    }
  } catch (error) {
    console.error("Email check error:", error);
    throw new Error("Internal server error");
  }
};

// 유저 정보 조회 API 호출 함수
export const fetchUserInfo = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_SB_URL}/user/info`);
    return response.data.item; // 유저 정보 반환
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw new Error("An error occurred while fetching user information");
  }
};
