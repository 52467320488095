import React, { useState } from "react";
import { LinkInputProps } from "../interfaces";

const LinkInput: React.FC<LinkInputProps> = ({
  value,
  placeholder,
  onChange,
  className,
  onValidChange,
}) => {
  const [isValid, setIsValid] = useState<boolean>(true);

  const validateDomain = (url: string) => {
    const domainRegex =
      /^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9_-]+)\.([a-zA-Z]{2,63})(\/[a-zA-Z0-9#]+\/?)*$/;
    return domainRegex.test(url);
  };

  const handleChange = (inputValue: string) => {
    onChange(inputValue);
    const valid = validateDomain(inputValue) || inputValue === "";
    setIsValid(valid);
    onValidChange(valid); // 유효성 상태 전달
  };

  return (
    <div className="flex flex-col">
      <input
        type="text"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className={className}
        placeholder={placeholder}
      />
      {!isValid && value.trim() !== "" && (
        <div className="text-red-500 text-xs mt-1">잘못된 URL입니다.</div>
      )}
    </div>
  );
};

export default LinkInput;
