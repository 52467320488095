import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <div className="w-[390px] h-16 px-4 bg-white border-t border-[#f1f1f3] justify-between items-center inline-flex">
        <div className="flex-col justify-start items-center inline-flex">
          <div className="w-9 h-9 justify-center items-center inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66663 5L4.41663 6.75H19.5833L21.3333 5H2.66663ZM2.66663 10.8333L4.41663 12.5833H19.5833L21.3333 10.8333H2.66663ZM4.41663 17.25L2.66663 19H21.3333L19.5833 17.25H4.41663Z"
                fill="#A1A1A1"
              />
            </svg>
          </div>
          <div className="text-center text-[#a1a1a1] text-xs">쇼핑</div>
        </div>
        <div className="flex-col justify-start items-center inline-flex">
          <div className="w-9 h-9 justify-center items-center inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33337 20V4H5.33338H6.83337H19.8334L21.3334 5.5L21.3334 18.5L19.8334 20L6.83337 20H5.33337ZM6.83337 18.5H19.8334V16.5L16.8334 16.5L15.3334 15V9L16.8334 7.5L19.8334 7.5V5.5L6.83337 5.5L6.83337 18.5ZM19.8334 9L19.8334 15H16.8334V9H19.8334Z"
                fill="#A1A1A1"
              />
              <rect x="18.3334" y="11" width="2" height="2" fill="#A1A1A1" />
            </svg>
          </div>
          <div className="text-center text-[#a1a1a1] text-xs">지갑</div>
        </div>
        <div className="flex-col justify-start items-center inline-flex">
          <div className="w-9 h-9 justify-center items-center inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.66479 5.50006L6.16486 4H11.1651L12.6651 5.50006H4.66479ZM12.6665 5.50006L14.1666 4H19.1668L20.6669 5.50006H12.6665ZM4.66479 5.50087H6.16486V11.4996L12.6651 17.9999V20L4.66628 12.0011H4.66479V11.9996V9.99957V5.50087ZM20.664 5.50087H19.1639V11.5025L12.6665 17.9999V20L20.6669 11.9996V9.99957L20.664 10.0024V5.50087ZM11.9113 5.50087V8.50099H13.4114V5.50087H11.9113Z"
                fill="#A1A1A1"
              />
            </svg>
          </div>
          <div className="text-center text-[#a1a1a1] text-xs">커뮤니티</div>
        </div>
        <div className="flex-col justify-start items-center inline-flex">
          <div className="w-9 h-9 justify-center items-center inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 4H14.5L15.5 5V10L14.5 11H9.5L8.5 10V5L9.5 4ZM10 9.5L13.995 9.555H14V5.5H10V9.5ZM5.5 14V20H4V14L5.5 12.5H18.5L20 14V19.5V20H18.5V14H5.5Z"
                fill="#212121"
              />
            </svg>
          </div>
          <div className="text-center text-[#212121] text-xs">마이</div>
        </div>
      </div>
      <div className="h-12 bg-white flex-col justify-end items-center inline-flex">
        <div className="h-6 px-2 py-2.5 flex-col justify-start items-center flex"></div>
      </div>
    </>
  );
};

export default Footer;
