import React from "react";
import { useNavigate } from "react-router-dom";
import { SubmitButtonProps } from "../interfaces";

const SubmitButton: React.FC<SubmitButtonProps> = ({
  text,
  isDisabled,
  link,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isDisabled) {
      if (link) {
        navigate(link);
      } else if (onClick) {
        onClick();
      }
    }
  };

  return (
    <button
      disabled={isDisabled}
      onClick={handleClick}
      className={`w-full p-3 text-white text-sm ${
        isDisabled
          ? "bg-[#bbbbbb] cursor-not-allowed"
          : "bg-[#212121] cursor-pointer"
      }`}
    >
      {text}
    </button>
  );
};

export default SubmitButton;
