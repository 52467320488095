import api from "../api/cw/api";
import FormData from "form-data";

export const uploadFile = async (
  file: File,
  path: string
): Promise<string | null> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("path", path);

  try {
    const response = await api.post("/file/upload", formData);
    if (response.data.message) {
      return response.data.message;
    }

    return null;
  } catch (error) {
    console.error("File upload failed:", error);
    return null;
  }
};

// Base64 파일을 업로드하는 함수
export const uploadBase64File = async (
  base64Data: string, // Base64 데이터를 받음
  path: string
): Promise<string | null> => {
  const formData = new FormData();

  // Base64 데이터를 FormData에 추가 (서버에서 Base64 해석 필요)
  formData.append("file", base64Data);
  formData.append("path", path);

  try {
    const response = await api.post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.message) {
      return response.data.message;
    }

    return null;
  } catch (error) {
    console.error("Base64 file upload failed:", error);
    return null;
  }
};

export const uploadBufferFile = async (
  blob: Blob, // Blob을 받도록 변경
  path: string
): Promise<string | null> => {
  const formData = new FormData();

  // Blob 데이터를 FormData에 추가
  formData.append("file", blob, "collage.png");
  formData.append("path", path);

  try {
    const response = await api.post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.message) {
      return response.data.message;
    }

    return null;
  } catch (error) {
    console.error("Buffer file upload failed:", error);
    return null;
  }
};

export const uploadJsonFile = async (
  jsonData: any,
  path: string
): Promise<string | null> => {
  const formData = new FormData();
  const jsonBlob = new Blob([JSON.stringify(jsonData)], {
    type: "application/json",
  });

  const fileName = "metadata.json";
  const extension = ".json";

  formData.append("file", jsonBlob, fileName);
  formData.append("path", path);
  formData.append("extension", extension);

  try {
    const response = await api.post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.message) {
      return response.data.message;
    }

    return null;
  } catch (error) {
    console.error("JSON file upload failed:", error);
    return null;
  }
};
